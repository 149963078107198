import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import util, { isElectron } from '../util';
import LinearGradient from '../components/LinearGradient';
import { Box, ButtonBase, Icon, IconButton, Typography } from '@mui/material';
import Tag from '../components/Tag';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { useSwipeable } from 'react-swipeable';
import { isOffline, isServerDown } from '../App';
import Image from '../components/Image';

export const GameCard = ({ game, type, navigate }) => {
    let version = Array.isArray(game.versions) ? game.versions[game.versions.length - 1] : game.versions
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div
            style={{
                width: 300,
                height: 260,
                margin: 10,
                borderRadius: 10,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                justifyContent: "space-between",
                position: "relative",
                cursor: "pointer",
                flexShrink: 0,
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => navigate(`/games/${game.appID}/${version.versionID}`)}
        >
            <div style={{ width: "100%", height: 210, display: "flex", borderRadius: 10, overflow: 'hidden', position: 'relative' }}>
                <Image src={version.thumbnail.url || version.thumbnail} style={{
                    width: "100%", height: 210, objectFit: "cover",
                    filter: isHovered ? "blur(5px) brightness(0.7)" : "none",
                    transition: "0.2s", position: "absolute", zIndex: 0,
                }} />
            </div>

            <div
                style={{
                    zIndex: 1,
                    position: "absolute",
                    bottom: 60, left: 10,
                }}
            >
                <div
                    style={{
                        height: isHovered ? 30 : 0,
                        overflow: "hidden",
                        transition: "0.5s",
                    }}
                >
                    {version.tags.slice(0, 3).map((tag, i) => {
                        return <Tag key={i} text={tag?.name || tag} style={{ marginRight: 5 }} />
                    })}
                </div>
            </div>

            <div
                style={{
                    zIndex: 1,
                    position: "absolute",
                    top: 10,
                    width: "100%"
                }}
            >
                <div
                    style={{
                        backdropFilter: "blur(5px)",
                        width: "100%",
                        display: game.underReview ? "block" : "none",
                    }}
                >
                    <Typography variant="p" style={{ color: "red", fontSize: 16, textWrap: "wrap", margin: 0, lineHeight: 1, fontWeight: "bold" }}>GAME UNDER REVIEW<br />UNLISTED</Typography>
                </div>
            </div>

            <Typography variant="p" style={{ color: "white", zIndex: 1, textAlign: "left", fontSize: 16, position: "absolute", bottom: 0, left: 10, width: 260, height: 45, textWrap: "wrap" }}>{game.name}</Typography>
        </div>
    )
}

const HorizontalScroller = ({ children }) => {
    return (
        <div
            style={{
                overflow: "visible",
                whiteSpace: "nowrap",
                padding: 10,
                display: "inline-flex",
                width: "100%",
            }}
        >
            {children}
        </div>
    )
}

const HorizontalScrollerWithArrows = ({ games, navigate }) => {
    const [scrollX, setScrollX] = useState(0);
    const [startScrollX, setStartScrollX] = useState(0);
    const cardWidth = 325; // width of GameCard + margin
    const maxScroll = -(games.length * cardWidth - (window.innerWidth > 1488 ? 1488 : window.innerWidth - 20) + 20); // 20 is padding

    const handlers = useSwipeable({
        onSwiping: (eventData) => {
            let newScrollX = startScrollX + eventData.deltaX;
            if (newScrollX < maxScroll) {
                newScrollX = maxScroll;
            } else if (newScrollX > 0) {
                newScrollX = 0;
            }
            setScrollX(newScrollX);
        },
        onSwiped: () => {
            setStartScrollX(scrollX);
        },
    });

    const allContentVisible = false//games.length * cardWidth <= window.innerWidth;

    return (
        <div
            style={{
                overflowX: "hidden",
                position: "relative",
            }}
        >
            <div
                style={{
                    transform: `translateX(${scrollX}px)`,
                    transition: "0.5s",
                }}
                {...handlers}
            >
                <HorizontalScroller>
                    {games.map((game, i) => {
                        return <GameCard key={i} game={game} navigate={navigate} />
                    })}
                </HorizontalScroller>
            </div>

            {!allContentVisible && scrollX < 0 && (
                <div
                    style={{
                        display: "flex",
                        position: "absolute",
                        top: 20,
                        justifyContent: "center",
                        width: 70,
                        height: 240,
                        zIndex: 10,
                    }}
                >
                    <LinearGradient x1={0} y1={0} x2={0} y2={-1} color1='#00000000' color2='#151515ff' />
                    <IconButton
                        style={{
                            backgroundColor: "#00000000",
                            color: "white",
                            zIndex: 5,
                            position: "absolute",
                            alignSelf: "center",
                            justifyContent: "center",
                            height: 200,
                            top: 0,
                            width: 70,
                            borderRadius: 0,
                            "-webkit-mask-image": "linear-gradient(to right, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 100%)",
                            "-webkit-mask-size": "100% 100%",
                            "-webkit-mask-repeat": "no-repeat",
                        }}
                        TouchRippleProps={{
                            style: {
                                color: "rgba(255, 255, 255, 0.5)"
                            }
                        }}
                        onClick={() => {
                            if (scrollX < 0) {
                                setScrollX(Math.min(scrollX + 400, 0));
                            }
                        }}
                    >
                        <ArrowBackIos />
                    </IconButton>
                </div>
            )}

            {!allContentVisible && scrollX > maxScroll && (<div
                style={{
                    display: "flex",
                    position: "absolute",
                    top: 20,
                    justifyContent: "center",
                    width: 70,
                    height: 240,
                    zIndex: 10,
                    right: 0
                }}
            >
                <LinearGradient x1={0} y1={0} x2={0} y2={1} color1='#00000000' color2='#1c1c1cff' />
                <IconButton
                    style={{
                        backgroundColor: "#00000000",
                        color: "white",
                        zIndex: 5,
                        position: "absolute",
                        alignSelf: "center",
                        justifyContent: "center",
                        height: 200,
                        top: 0,
                        width: 70,
                        borderRadius: 0,
                        "-webkit-mask-image": "linear-gradient(to left, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 100%)",
                        "-webkit-mask-size": "100% 100%",
                        "-webkit-mask-repeat": "no-repeat",
                    }}
                    TouchRippleProps={{
                        style: {
                            color: "rgba(255, 255, 255, 0.5)"
                        }
                    }}
                    onClick={() => {
                        if (scrollX > maxScroll) {
                            setScrollX(Math.max(scrollX - 405, maxScroll));
                        }
                    }}
                >
                    <ArrowForwardIos />
                </IconButton>
            </div>
            )}

        </div>
    )
}

const GameStripWithTitle = ({ title, games, navigate }) => {
    return (
        <div>
            <ButtonBase
                disabled={["Popular Games", "New Games", "Recommended Games", "Recently Updated"].includes(title)}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    margin: -10,
                    marginTop: 0,
                    marginLeft: 10,
                    padding: 10,
                    borderRadius: 6,
                }}
                onClick={() => {
                    navigate(`/tags/${encodeURIComponent(title)}`);
                }}
            >
                <Typography variant="h5" style={{ color: "white", textAlign: "left" }}>{title}</Typography>
            </ButtonBase>

            <HorizontalScrollerWithArrows games={games} navigate={navigate} />
        </div>
    )
}

const Home = (props) => {
    const navigate = useNavigate();
    const [games, setGames] = useState([]);
    const [newGames, setNewGames] = useState([])
    const [popularGames, setPopularGames] = useState([])
    const [recommendedGames, setRecommendedGames] = useState([])
    const [tagCategories, setTagCategories] = useState([]);
    const [updatedGames, setUpdatedGames] = useState([]);

    useEffect(() => {
        (async () => {
            await caches.open("request-cache").then(async (cache) => {
                let response = await cache.match("INDEX_GAMES");

                if (response && games.length === 0) { // only set if it hasn't been set by the network fetch
                    response = await response.json()
                    setGames(response);

                    let sortedNew = [...response].sort((a, b) => {
                        return new Date(b.created) - new Date(a.created);
                    })

                    setNewGames(sortedNew.slice(0, 10));

                    let popularity = sortedNew.sort((a, b) => {
                        return b.playCount - a.playCount;
                    })

                    setPopularGames(popularity.slice(0, 10));

                    let verdictSort = sortedNew.sort((a, b) => {
                        if (b.verdict === a.verdict) { // If verdicts are the same
                            return b.playCount - a.playCount; // Sort by playCount
                        }
                        return b.verdict - a.verdict; // Otherwise, sort by verdict
                    });

                    setRecommendedGames(verdictSort.slice(0, 10));

                    // sort games by their latest version's created date
                    let sortedUpdated = [...response].sort((a, b) => {
                        return new Date(b.versions[b.versions.length - 1].created) - new Date(a.versions[a.versions.length - 1].created);
                    })

                    setUpdatedGames(sortedUpdated.slice(0, 10));

                    let TagCategories = [];

                    response.forEach(game => {
                        game.versions.forEach(version => {
                            version.tags.forEach(tag => {
                                let found = false;
                                for (let i = 0; i < TagCategories.length; i++) {
                                    if (TagCategories[i].name === tag && !TagCategories[i].games.includes(game)) {
                                        TagCategories[i].games.push(game);
                                        found = true;
                                        break;
                                    }
                                }
                                if (!found && TagCategories.filter((category) => category.name === tag).length === 0) {
                                    TagCategories.push({
                                        name: tag,
                                        games: [game]
                                    })
                                }
                            })
                        })
                    })

                    TagCategories.sort((a, b) => {
                        return b.games.length - a.games.length;
                    })

                    TagCategories = TagCategories.slice(0, 10);

                    TagCategories.forEach(category => {
                        category.games = category.games.sort((a, b) => {
                            return b.playCount - a.playCount;
                        })
                    })

                    TagCategories = TagCategories.sort((a, b) => {
                        return b.games.length - a.games.length;
                    })

                    setTagCategories(TagCategories);
                }
            })
        })()

        util.getRequest(`/api/games${props.adminMode ? "?getall=true" : ""}`, (res) => {
            setGames(res.games);

            caches.open("request-cache").then(async (cache) => {
                cache.put("INDEX_GAMES", new Response(JSON.stringify(res.games)));
            })

            let sortedNew = [...res.games].sort((a, b) => {
                return new Date(b.created) - new Date(a.created);
            })

            setNewGames(sortedNew.slice(0, 15));

            let popularity = sortedNew.sort((a, b) => {
                return b.playCount - a.playCount;
            })

            setPopularGames(popularity.slice(0, 15));

            let verdictSort = sortedNew.sort((a, b) => {
                if (b.verdict === a.verdict) { // If verdicts are the same
                    return b.playCount - a.playCount; // Sort by playCount
                }
                return b.verdict - a.verdict; // Otherwise, sort by verdict
            });

            setRecommendedGames(verdictSort.slice(0, 15));

            // sort games by their latest version's created date
            let sortedUpdated = [...res.games].sort((a, b) => {
                return new Date(b.versions[b.versions.length - 1].created) - new Date(a.versions[a.versions.length - 1].created);
            })

            setUpdatedGames(sortedUpdated.slice(0, 15));

            let TagCategories = [];

            res.games.forEach(game => {
                let version = game["versions"][game["versions"].length - 1];
                version.tags.forEach(tag => {
                    let found = false;
                    for (let i = 0; i < TagCategories.length; i++) {
                        if (TagCategories[i].name === tag && !TagCategories[i].games.includes(game)) {
                            TagCategories[i].games.push(game);
                            found = true;
                            break;
                        }
                    }
                    if (!found && TagCategories.filter((category) => category.name === tag).length === 0) {
                        TagCategories.push({
                            name: tag,
                            games: [game]
                        })
                    }
                })

            })

            TagCategories.sort((a, b) => {
                return b.games.length - a.games.length;
            })

            TagCategories = TagCategories.slice(0, 10);

            TagCategories.forEach(category => {
                category.games = category.games.sort((a, b) => {
                    return b.playCount - a.playCount;
                }).slice(0, 10);
            })

            TagCategories = TagCategories.sort((a, b) => {
                return b.games.length - a.games.length;
            })

            setTagCategories(TagCategories);
        });
    }, [isOffline]);

    if (isOffline) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 'calc(100vh - 68.5px)'
                }}
            >
                <Typography variant="h3" component="h1" gutterBottom>
                    {isServerDown ? "Server Unreachable" : "You are offline"}
                </Typography>

                <Typography variant="p" gutterBottom>
                    {isServerDown ? "Whoops..." : "Please check your internet connection and try again."}
                </Typography>

                <Typography variant="p" gutterBottom>
                    Why not play a game while you wait?
                </Typography>

                <Button variant="contained" color="primary" onClick={() => {
                    navigate("/library");
                }}>
                    Let's Go!
                </Button>
            </Box>
        )
    }

    return (
        <div
            style={{
                marginTop: 20,
                position: "relative",
            }}
        >
            <Button
                sx={{
                    position: "absolute",
                    top: 0,
                    right: 5,
                }}
                variant='contained'
                onClick={() => {
                    // select a random game from the list
                    const randomGame = games[Math.floor(Math.random() * games.length)];
                    const version = randomGame.versions[randomGame.versions.length - 1];
                    navigate(`/games/${randomGame.appID}/${version.versionID}`);
                }}
            >
                Surprise Me
            </Button>

            <GameStripWithTitle navigate={navigate} title="Popular Games" games={popularGames} />
            <GameStripWithTitle navigate={navigate} title="New Games" games={newGames} />
            <GameStripWithTitle navigate={navigate} title="Recommended Games" games={recommendedGames} />
            <GameStripWithTitle navigate={navigate} title="Recently Updated" games={updatedGames} />
            {
                tagCategories.map((tagCategory, i) => {
                    return (
                        <GameStripWithTitle key={i} navigate={navigate} title={tagCategory.name} games={tagCategory.games} />
                    )
                })
            }
        </div>
    );
};

export default Home;