import Button from '@mui/material/Button';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import util, { isElectron } from '../util';
import { Box, IconButton, Typography, Rating, CircularProgress, Tooltip, FormControl, TextField, ButtonBase, Slider, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useSwipeable } from 'react-swipeable';
import { ArrowBackIos, Block, Delete, Download, Edit, Flag, Forward10, Forward5, LibraryAdd, Notifications, NotificationsActive, NotificationsOff, Pause, PersonAdd, PlayArrow, Replay5, Reply, Share, ThumbDown, ThumbDownAltOutlined, ThumbDownOutlined, ThumbUp, ThumbUpOutlined, ThumbUpSharp, Visibility, VisibilityOff, VolumeDown, VolumeOff, VolumeUp, VolunteerActivism } from '@mui/icons-material';
import LinearGradient from '../components/LinearGradient';
import { snackCaller, loadingCaller, callReportModal, callGenericModal, hideModal, callLoadingLine, navigate } from '../App';
import { styled } from '@mui/system';
import { keyframes } from '@emotion/react';
import Pagination from '@mui/material/Pagination';
import Image from '../components/Image';
import Video from '../components/Video';
import Markdown from '../components/Markdown';

const ratingStates = {
    0: "No Rating",
    1: "Negative", // < 20%
    2: "Mostly Negative", // < 40%
    3: "Neutral", // > 40% < 60%
    4: "Mostly Positive", // > 60% < 80%
    5: "Positive" // > 80%
}

const getRatingState = (verdict) => {
    if (verdict === 0) return ratingStates[3]; // Neutral for exactly 0
    if (verdict <= -80) return ratingStates[1]; // Negative for -100 to -80
    if (verdict < -60) return ratingStates[2]; // Mostly Negative for -79 to -60
    // Adjusted for symmetry
    if (verdict < 0) return ratingStates[3]; // Neutral for -59 to -1
    if (verdict < 20) return ratingStates[3]; // Still Neutral for 1 to 19
    if (verdict < 40) return ratingStates[4]; // Mostly Positive for 20 to 39
    return ratingStates[5]; // Positive for 40 to 100
}

function Comment({ comment, user, gameData, setGameData, isSub = false, childCount = 0, maxC = 3, p, navigate, setIsBounce }) {
    const [isExpanded, setIsExpanded] = useState(comment.comment.length < 360);

    if (isSub) childCount++

    if (childCount > maxC && maxC !== -1) {
        return (
            <div
                style={{
                    display: "flex",
                    marginTop: 10
                }}
            >
                <Button onClick={() => {
                    setIsBounce(false)
                    let pUID = p?.UUID || comment.parentComment
                    let pC = gameData.game.comments.find(comment => comment.UUID === pUID)
                    callGenericModal("Thread View", <Comment setIsBounce={setIsBounce} maxC={-1} setGameData={setGameData} gameData={gameData} user={user} comment={pC} />, [])

                }} variant="text" color="primary">See All</Button>
            </div>
        )
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#202020",
                padding: 10,
                borderRadius: 4,
                marginTop: 10,
                width: "100%",
            }}
        >

            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                        }}
                    >
                        <IconButton
                            sx={{
                                padding: 0,
                                margin: 0,
                                width: 50,
                                height: 50,
                                marginRight: 1.5,
                                position: "relative",
                            }}
                            onClick={() => {
                                setIsBounce(false)
                                setTimeout(() => {
                                    navigate(`/profile/${comment.user.UUID}`)
                                }, 100);

                            }}
                        >
                            <Image
                                src={comment.user.avatar || "https://cdn.vorecade.com/defaults/avatar.webp"}
                                style={{
                                    width: 50,
                                    height: 50,
                                    borderRadius: 25,
                                }}
                            />
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: 0,
                                    right: 0,
                                    width: 15,
                                    height: 15,
                                    borderRadius: 10,
                                    backgroundColor: comment.user.isOnline ? "#00ff00" : "#ff0000",
                                    border: "2px solid #000000",
                                    boxShadow: `0px 0px 5px 1px ${comment.user.isOnline ? "#00ff00" : "red"}`,
                                    zIndex: 2
                                }}
                            >

                            </div>
                        </IconButton>
                        <Typography
                            variant="h6"
                            component="p"
                            sx={{
                                textAlign: "left",
                            }}
                        >{comment.user.username}</Typography>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            height: 40
                        }}
                    >
                        {isSub ? "" : comment.positive ? <Tooltip title={`${comment.user.username} liked this`} ><IconButton><ThumbUp style={{ color: "#00aa00" }} /></IconButton></Tooltip> : <Tooltip title={`${comment.user.username} disliked this`} ><IconButton><ThumbDown style={{ color: "#aa0000" }} /></IconButton></Tooltip>}
                        <Tooltip title="Reply">
                            <IconButton
                                onClick={() => {
                                    setIsBounce(false)
                                    callGenericModal("Reply",
                                        <GameCommentModal
                                            appID={gameData.game.appID}
                                            versionID={gameData.version.versionID}
                                            gameData={gameData}
                                            editing={true}
                                            setGameData={setGameData}
                                            isReply={true}
                                            commentData={comment}
                                            isSub={isSub}
                                        />
                                        , [])
                                }}
                            >
                                <Reply />
                            </IconButton>
                        </Tooltip>
                        {comment.user.UUID === user.UUID ?
                            <div
                                style={{

                                }}
                            >
                                <Tooltip title="Edit">
                                    <IconButton
                                        onClick={() => {
                                            setIsBounce(false)
                                            console.log(comment.comment)
                                            callGenericModal("Edit your Comment",
                                                <GameCommentModal
                                                    appID={gameData.game.appID}
                                                    versionID={gameData.version.versionID}
                                                    gameData={gameData}
                                                    ecomment={comment.comment}
                                                    positive={comment.positive}
                                                    editing={true}
                                                    setGameData={setGameData}
                                                    commentData={comment}
                                                    isSub={isSub}
                                                />
                                                , [])
                                        }}
                                    >
                                        <Edit />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Delete">
                                    <IconButton
                                        onClick={() => {
                                            if (!localStorage.getItem("token")) {
                                                snackCaller("You need to be logged in to delete comments.")
                                                navigate("/login")
                                                return
                                            }

                                            setIsBounce(false)
                                            util.postRequest("/api/games/comment/delete", { commentID: comment.UUID }).then(() => {
                                                util.postRequest('/api/games/gameMeta', { appID: gameData.game.appID, versionID: gameData.version.versionID })
                                                    .then(data => {
                                                        setGameData(data);
                                                    });
                                            })
                                        }}
                                    >
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            :
                            <div
                                style={{

                                }}
                            >
                                <Tooltip title="Report">
                                    <IconButton
                                        onClick={() => {
                                            setIsBounce(false)
                                            callReportModal({
                                                type: "GameComment",
                                                title: comment.user.username,
                                                id: comment.UUID
                                            })
                                        }}
                                    >
                                        <Flag />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        }
                    </div>
                </div>
                <Typography
                    variant="p"
                    component="p"
                    sx={{
                        textAlign: "left",
                        color: comment.comment ? "#ffffff" : "#9b9b9b",
                        flexWrap: "wrap",
                        wordBreak: "break-all",
                        fontSize: 14,
                        fontWeight: 500,
                        cursor: !isExpanded ? "pointer" : "default",
                        marginTop: 1
                    }}
                    onClick={() => !isExpanded && setIsExpanded(!isExpanded)}
                >{comment.comment ? comment.comment.slice(0, isExpanded ? 9999 : 360) + `${!isExpanded ? "..." : ""}` : "No Comment."}</Typography>
                <Typography
                    variant="p"
                    component="p"
                    sx={{
                        textAlign: "left",
                        color: "#9b9b9b"
                    }}
                >
                    {`Posted on ${new Date(comment.created).toLocaleDateString()} ${new Date(comment.created).toLocaleTimeString()}${comment.edited ? ` | Edited on ${new Date(comment.edited).toLocaleDateString()} ${new Date(comment.edited).toLocaleTimeString()}` : ""} `}
                </Typography>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: 'column',
                    marginRight: -10
                }}
            >
                {
                    comment.subcomments && comment.subcomments[0]?.UUID && comment.subcomments.map(subcomment => <Comment setIsBounce={setIsBounce} navigate={navigate} p={p} maxC={maxC} comment={subcomment} user={user} gameData={gameData} isSub={true} childCount={childCount} setGameData={setGameData} />)
                }
            </div>
        </div>
    );
}

function hexToRgb(hex) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

const Carousel = ({ images, gameData, appID, versionID, setGameData, setIsBounce, adminMode }) => {
    const [index, setIndex] = useState(0);
    const navigate = useNavigate();
    const [muted, setMuted] = useState(true);
    const [hovered, setHovered] = useState(false);
    const [volume, setVolume] = useState(0);
    const [isVideo, setIsVideo] = useState(false);

    const handlers = useSwipeable({
        onSwipedLeft: () => setIndex(index === images.length - 1 ? 0 : index + 1),
        onSwipedRight: () => setIndex(index === 0 ? images.length - 1 : index - 1),
    });

    const handleNext = () => {
        let vid = document.getElementById(`vid_${index}`)
        if (vid) {
            vid.pause()
            vid.muted = true
            document.getElementById(`vid_play_${index}`).style.display = "block"
            document.getElementById(`vid_pause_${index}`).style.display = "none"
        }

        let nvid = document.getElementById(`vid_${index + 1}`)
        if (!nvid && index + 1 === images.length) {
            nvid = document.getElementById(`vid_0`) // loop
        }
        if (nvid) {
            try {
                nvid.play()
            } catch (e) {
                console.log(e)
            }
            nvid.muted = muted
            nvid.volume = volume / 100
            let vidIND = nvid.id.split("_")[1]
            document.getElementById(`vid_play_${vidIND}`).style.display = "none"
            document.getElementById(`vid_pause_${vidIND}`).style.display = "block"
            setIsVideo(true)
        } else {
            setIsVideo(false)
        }

        setIndex(index === images.length - 1 ? 0 : index + 1);
    };

    const handlePrev = () => {
        let vid = document.getElementById(`vid_${index}`)
        if (vid) {
            vid.pause()
            vid.muted = true
            document.getElementById(`vid_play_${index}`).style.display = "block"
            document.getElementById(`vid_pause_${index}`).style.display = "none"
        }

        let nvid = document.getElementById(`vid_${index - 1}`)
        if (!nvid && index - 1 === -1) {
            nvid = document.getElementById(`vid_${images.length - 1}`) // loop
        }

        if (nvid) {
            nvid.play()
            nvid.muted = muted
            nvid.volume = volume / 100
            let vidIND = nvid.id.split("_")[1]
            document.getElementById(`vid_play_${vidIND}`).style.display = "none"
            document.getElementById(`vid_pause_${vidIND}`).style.display = "block"
            setIsVideo(true)
        } else {
            setIsVideo(false)
        }

        setIndex(index === 0 ? images.length - 1 : index - 1);
    };

    const [isGameInstalled, setIsGameInstalled] = useState(false);
    const [isGameUpdate, setIsGameUpdate] = useState(false);
    const [requiredUpdate, setRequiredUpdate] = useState(false);
    const [newerVersionID, setNewerVersionID] = useState(0);

    useEffect(() => {
        if (isElectron()) {
            window.ipcRenderer.invoke("getLocalGameList").then((res) => {
                console.log(res)
                const localGame = res.find(game => game.game.appID === parseInt(appID));
                console.log(appID)
                if (localGame) {
                    setIsGameInstalled(true)

                    const localVersionIndex = gameData.versions.findIndex(version => version?.versionID === localGame.version?.versionID);
                    const newerVersions = gameData.versions.slice(localVersionIndex + 1);
                    const newerHardUpdateVersion = newerVersions.find(version => version.isHardUpdate);

                    if (newerHardUpdateVersion) {
                        setIsGameUpdate(true)
                        setNewerVersionID(newerHardUpdateVersion.versionID);
                        setRequiredUpdate(newerHardUpdateVersion.requiredUpdate)
                    }
                }
            })
        }

        images[0].endsWith(".mp4") && setIsVideo(true)
    }, [])

    const carouselHeight = 570;

    return (
        <div id="carousel" style={{ aspectRatio: "16 / 9", height: carouselHeight, position: "relative", overflow: 'hidden', borderRadius: 4, overflow: "hidden", maxWidth: "100%" }} {...handlers}>
            {gameData.game.underReview && adminMode && <div
                style={{
                    zIndex: 5,
                    position: "absolute",
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    top: 0,
                    padding: 10,
                    backdropFilter: "blur(5px)",
                }}
            >
                <Typography variant="p" style={{ color: "red", fontSize: 16, textWrap: "wrap", margin: 0, lineHeight: 1, fontWeight: "bold" }}>
                    VIEWING IN ADMIN MODE, THIS GAME IS NOT VISIBLE TO USERS
                </Typography>
            </div>}

            <div
                style={{
                    position: "absolute",
                    bottom: 20,
                    left: 20,
                    zIndex: 3,
                }}
            >
                <div
                    style={{
                        zIndex: 5,
                        position: "absolute",
                        width: "100%",
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        top: 5
                    }}
                >
                    {gameData.game.underReview && !adminMode && <div
                        style={{
                            backdropFilter: "blur(5px)",
                            width: "100%",
                        }}
                    >
                        <Typography variant="p" style={{ color: "red", fontSize: 16, textWrap: "wrap", margin: 0, lineHeight: 1, fontWeight: "bold" }}>GAME UNDER REVIEW<br />UNAVAILABLE</Typography>
                    </div>}
                </div>

                {gameData.game.isOwned ?
                    isElectron() ?
                        !isGameInstalled ?
                            <Button disabled={gameData.game.underReview} style={{ padding: 10, fontSize: 20, fontWeight: "bold", color: "#fff" }} variant="contained" color="success" onClick={async () => {
                                setIsBounce(false)
                                callLoadingLine(0, gameData.game.name + " downloading...")
                                await window.ipcRenderer.invoke("installGame", { gameID: appID, versionID: versionID, gameMeta: gameData })

                                window.ipcRenderer.invoke("getLocalGameList", { force: true }).then((res) => {
                                    const localGame = res.find(game => game.game.appID === parseInt(appID));
                                    if (localGame && localGame.version) {
                                        setIsGameInstalled(true)

                                        const localVersionIndex = gameData.versions.findIndex(version => version.versionID === localGame.version.versionID);
                                        const newerVersions = gameData.versions.slice(localVersionIndex + 1);
                                        const newerHardUpdateVersion = newerVersions.find(version => version.isHardUpdate);

                                        if (newerHardUpdateVersion) {
                                            setIsGameUpdate(true)
                                            setNewerVersionID(newerHardUpdateVersion.versionID);
                                        }
                                    }
                                })
                            }}><Download style={{ marginRight: 10 }} />Download</Button>
                            :
                            isGameUpdate ?
                                <div>
                                    <Button disabled={gameData.game.underReview} style={{ padding: 10, fontSize: 20, fontWeight: "bold", color: "#fff", borderTopRightRadius: 0, borderBottomRightRadius: 0, paddingRight: 17 }} variant="contained" color="success" onClick={async () => {
                                        setIsBounce(false)
                                        callLoadingLine(0, gameData.game.name + " downloading...")

                                        await window.ipcRenderer.invoke("installGame", { gameID: appID, versionID: versionID, gameMeta: gameData })
                                        setIsGameUpdate(false)
                                        window.ipcRenderer.invoke("getLocalGameList", { force: true }).then((res) => {
                                            const localGame = res.find(game => game.game.appID === parseInt(appID));
                                            if (localGame) {
                                                setIsGameInstalled(true)

                                                const localVersionIndex = gameData.versions.findIndex(version => version.versionID === localGame.version.versionID);
                                                const newerVersions = gameData.versions.slice(localVersionIndex + 1);
                                                const newerHardUpdateVersion = newerVersions.find(version => version.isHardUpdate);

                                                if (newerHardUpdateVersion) {
                                                    setIsGameUpdate(true)
                                                    setNewerVersionID(newerHardUpdateVersion.versionID);
                                                }
                                            }
                                        })
                                    }}><Download style={{ marginRight: 10 }} />Update</Button>
                                    {!requiredUpdate && <Button style={{ padding: 10, fontSize: 20, fontWeight: "bold", color: "#fff", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} variant="contained" color="success" onClick={() => {
                                        setIsBounce(false)
                                        callLoadingLine(0, gameData.game.name + " launching...")
                                        setTimeout(async () => {
                                            let launchResult = await window.ipcRenderer.invoke("launchGame", { appID: parseInt(appID), versionID: parseInt(versionID) })
                                            if (!launchResult) {
                                                snackCaller("Failed to launch game")
                                            }
                                        }
                                            , 500);
                                    }}><PlayArrow style={{ marginRight: 10 }} />Play</Button>}
                                </div>
                                :
                                <Button style={{ padding: 10, fontSize: 20, fontWeight: "bold", color: "#fff" }} variant="contained" color="success" onClick={() => {
                                    setIsBounce(false)
                                    loadingCaller(true, gameData.game.name + " launching...")

                                    setTimeout(async () => {
                                        let launchResult = await window.ipcRenderer.invoke("launchGame", { appID: parseInt(appID), versionID: parseInt(versionID) })

                                        loadingCaller(false)
                                        if (!launchResult) {
                                            snackCaller("Failed to launch game")
                                        }
                                    }, 500);
                                }}><PlayArrow style={{ marginRight: 10 }} />Play</Button>
                        :
                        false//gameData.game.platform === "web" 
                            ?
                            <Button style={{ padding: 10, fontSize: 20, fontWeight: "bold", color: "#fff" }} variant="contained" color="success" onClick={() => {
                                // FIXME - This is a temporary solution to play web games

                                // open the game in a new tab as a popup

                                const newWindow = window.open(gameData.versions[gameData.versions.length - 1].executable.url, '_blank', 'height=600,width=800');
                                if (window.focus) newWindow.focus();
                            }}><PlayArrow style={{ marginRight: 10 }} />Play</Button>
                            :
                            <Button disabled={gameData.game.underReview} style={{ padding: 10, fontSize: 20, fontWeight: "bold", color: "#fff" }} variant="contained" color="success" onClick={() => {
                                setIsBounce(false)
                                navigate("/download")
                            }}><Download style={{ marginRight: 10 }} />Download Vorecade</Button>
                    :
                    localStorage.getItem("token") //|| gameData.game.allowAnonymous 
                        ?
                        <Button disabled={gameData.game.underReview} style={{ padding: 10, fontSize: 20, fontWeight: "bold", color: "#fff" }} variant="contained" color="success" onClick={() => {
                            if (!localStorage.getItem("token")) {
                                snackCaller("You need to be logged in to add games to library.")
                                navigate("/login")
                                return
                            }

                            setIsBounce(false)
                            loadingCaller(true)
                            try {
                                util.postRequest("/api/games/purchase", { appID: parseInt(appID) }, () => { }, 'application/json', true).then(() => {
                                    loadingCaller(false)

                                    util.postRequest('/api/games/gameMeta', { appID, versionID })
                                        .then(data => {
                                            setGameData(data);
                                        });
                                })
                            } catch (e) {
                                loadingCaller(false)
                            }
                        }}><LibraryAdd style={{ marginRight: 10 }} />Add to Library</Button>
                        :
                        isElectron() ?
                            !isGameInstalled ?
                                <Button disabled={gameData.game.underReview} style={{ padding: 10, fontSize: 20, fontWeight: "bold", color: "#fff" }} variant="contained" color="success" onClick={async () => {
                                    setIsBounce(false)
                                    callLoadingLine(0, gameData.game.name + " downloading...")
                                    await window.ipcRenderer.invoke("installGame", { gameID: appID, versionID: versionID, gameMeta: gameData })

                                    window.ipcRenderer.invoke("getLocalGameList", { force: true }).then((res) => {
                                        const localGame = res.find(game => game.game.appID === parseInt(appID));
                                        if (localGame && localGame.version) {
                                            setIsGameInstalled(true)

                                            const localVersionIndex = gameData.versions.findIndex(version => version.versionID === localGame.version.versionID);
                                            const newerVersions = gameData.versions.slice(localVersionIndex + 1);
                                            const newerHardUpdateVersion = newerVersions.find(version => version.isHardUpdate);

                                            if (newerHardUpdateVersion) {
                                                setIsGameUpdate(true)
                                                setNewerVersionID(newerHardUpdateVersion.versionID);
                                            }
                                        }
                                    })
                                }}><Download style={{ marginRight: 10 }} />Download</Button>
                                :
                                isGameUpdate ?
                                    <div>
                                        <Button disabled={gameData.game.underReview} style={{ padding: 10, fontSize: 20, fontWeight: "bold", color: "#fff", borderTopRightRadius: 0, borderBottomRightRadius: 0, paddingRight: 17 }} variant="contained" color="success" onClick={async () => {
                                            setIsBounce(false)
                                            callLoadingLine(0, gameData.game.name + " downloading...")

                                            await window.ipcRenderer.invoke("installGame", { gameID: appID, versionID: versionID, gameMeta: gameData })
                                            setIsGameUpdate(false)
                                            window.ipcRenderer.invoke("getLocalGameList", { force: true }).then((res) => {
                                                const localGame = res.find(game => game.game.appID === parseInt(appID));
                                                if (localGame) {
                                                    setIsGameInstalled(true)

                                                    const localVersionIndex = gameData.versions.findIndex(version => version.versionID === localGame.version.versionID);
                                                    const newerVersions = gameData.versions.slice(localVersionIndex + 1);
                                                    const newerHardUpdateVersion = newerVersions.find(version => version.isHardUpdate);

                                                    if (newerHardUpdateVersion) {
                                                        //setIsGameUpdate(true)
                                                        setNewerVersionID(newerHardUpdateVersion.versionID);
                                                    }
                                                }
                                            })
                                        }}><Download style={{ marginRight: 10 }} />Update</Button>
                                        {!requiredUpdate && <Button style={{ padding: 10, fontSize: 20, fontWeight: "bold", color: "#fff", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} variant="contained" color="success" onClick={() => {
                                            setIsBounce(false)
                                            callLoadingLine(0, gameData.game.name + " launching...")
                                            setTimeout(async () => {
                                                let launchResult = await window.ipcRenderer.invoke("launchGame", { appID: parseInt(appID), versionID: parseInt(versionID) })
                                                if (!launchResult) {
                                                    snackCaller("Failed to launch game")
                                                }
                                            }
                                                , 500);
                                        }}><PlayArrow style={{ marginRight: 10 }} />Play</Button>}
                                    </div>
                                    :
                                    <Button style={{ padding: 10, fontSize: 20, fontWeight: "bold", color: "#fff" }} variant="contained" color="success" onClick={() => {
                                        setIsBounce(false)
                                        loadingCaller(true, gameData.game.name + " launching...")

                                        setTimeout(async () => {
                                            let launchResult = await window.ipcRenderer.invoke("launchGame", { appID: parseInt(appID), versionID: parseInt(versionID) })

                                            loadingCaller(false)
                                            if (!launchResult) {
                                                snackCaller("Failed to launch game")
                                            }
                                        }, 500);
                                    }}><PlayArrow style={{ marginRight: 10 }} />Play</Button>
                            :
                            <Button disabled={gameData.game.underReview} style={{ padding: 10, fontSize: 20, fontWeight: "bold", color: "#fff" }} variant="contained" color="success" onClick={() => {
                                setIsBounce(false)
                                navigate("/download")
                            }}><Download style={{ marginRight: 10 }} />Download Vorecade</Button>
                }
            </div>

            <div
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                style={{
                    position: 'absolute',
                    bottom: 10,
                    right: 10,
                    zIndex: 99999,
                    display: 'flex',
                    alignItems: 'center',
                    backdropFilter: 'blur(5px) brightness(0.85)',
                    transition: '0.3s ease-in-out',
                    borderRadius: 6,
                    display: isVideo ? 'flex' : 'none'
                }}
            >
                <Slider
                    value={volume}
                    min={0}
                    max={100}
                    onChange={(e, newValue) => {
                        setVolume(newValue)
                        let vid = document.getElementById(`vid_${index}`)
                        if (vid) {
                            vid.volume = newValue / 100
                            if (newValue === 0) {
                                vid.muted = true
                            } else {
                                vid.muted = false
                            }
                        }

                        if (newValue === 0) {
                            setMuted(true)
                        } else {
                            setMuted(false)
                        }
                    }}
                    aria-labelledby="continuous-slider"
                    sx={{
                        width: hovered ? 100 : 0,
                        marginLeft: hovered ? "20px" : 0,
                        marginRight: hovered ? "10px" : 0,
                        transition: "300ms",
                        opacity: hovered ? 1 : 0,
                        '& .MuiSlider-thumb': {
                            opacity: hovered ? 1 : 0
                        }
                    }}
                />

                <IconButton onClick={() => {
                    let vid = document.getElementById(`vid_${index}`)

                    if (muted) {
                        setVolume(50)

                        if (vid) {
                            vid.volume = 0.5
                            vid.muted = false
                        }
                    } else {
                        setVolume(0)

                        if (vid) {
                            vid.volume = 0
                            vid.muted = true
                        }
                    }
                    setMuted(!muted)
                }} style={{ width: 40 }}>
                    <VolumeUp style={{ display: !muted ? 'block' : 'none' }} />
                    <VolumeOff style={{ display: muted ? 'block' : 'none' }} />
                </IconButton>
            </div>

            <div style={{
                display: 'flex',
                width: `${images.length * 100}%`,
                transform: `translateX(-${(index / images.length) * 100}%)`,
                transition: 'transform 0.5s ease-in-out'
            }}>
                {images.map((image, i) => {
                    if (image === "") return null
                    if (image.endsWith(".mp4")) return (
                        <div
                            style={{ width: `${100 / images.length}%`, height: carouselHeight, objectFit: "cover", position: "relative" }}
                            onPointerEnter={() => {
                                document.getElementById(`vid_controls_${i}`).style.opacity = 1
                            }}
                            onPointerLeave={() => {
                                document.getElementById(`vid_controls_${i}`).style.opacity = 0
                            }}
                        >
                            {(Math.abs(index - i) <= 1 || (i === 0 && index === images.length - 1) || (i === images.length - 1 && index === 0)) && <Video src={image} onPlay={() => {
                                if ("mediaSession" in navigator) {
                                    navigator.mediaSession.metadata = new MediaMetadata({
                                        title: gameData.game.name,
                                        artist: gameData.game.developer.username,
                                        album: "Vorecade",
                                        artwork: [
                                            { src: gameData.version.thumbnail }
                                        ]
                                    });
                                }
                            }} key={i} id={`vid_${i}`} style={{ width: `100%`, height: "100%", objectFit: "contain", }} autoPlay={i === 0} loop muted />}
                            <div
                                style={{
                                    position: "absolute",
                                    width: "50%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    zIndex: 2,
                                    top: 0,
                                    transition: "250ms",
                                    opacity: 0,
                                    left: "25%",
                                }}
                                id={`vid_controls_${i}`}
                            >
                                <IconButton onClick={() => {
                                    let vid = document.getElementById(`vid_${i}`)
                                    vid.currentTime -= 5
                                }} style={{
                                    backdropFilter: "blur(5px) brightness(0.85)",
                                }}>
                                    <Replay5 />
                                </IconButton>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 10,
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <IconButton onClick={() => {
                                        let vid = document.getElementById(`vid_${i}`)
                                        if (vid.paused) {
                                            vid.play()
                                            document.getElementById(`vid_play_${i}`).style.display = "none"
                                            document.getElementById(`vid_pause_${i}`).style.display = "block"
                                        } else {
                                            vid.pause()
                                            document.getElementById(`vid_play_${i}`).style.display = "block"
                                            document.getElementById(`vid_pause_${i}`).style.display = "none"
                                        }
                                    }} style={{
                                        backdropFilter: "blur(5px) brightness(0.85)",
                                        width: 40
                                    }}>
                                        <PlayArrow id={`vid_play_${i}`} style={{ display: "none" }} />
                                        <Pause id={`vid_pause_${i}`} />
                                    </IconButton>
                                </div>

                                <IconButton onClick={() => {
                                    let vid = document.getElementById(`vid_${i}`)
                                    vid.currentTime += 5
                                }} style={{
                                    backdropFilter: "blur(5px) brightness(0.85)",
                                }}>
                                    <Forward5 />
                                </IconButton>
                            </div>
                        </div>
                    )
                    return (
                        <div
                            style={{
                                width: `${100 / images.length}%`, height: carouselHeight,

                            }}
                        >
                            {(Math.abs(index - i) <= 1 || (i === 0 && index === images.length - 1) || (i === images.length - 1 && index === 0)) && <Image key={i} src={image} style={{ width: "100%", height: "100%", objectFit: "contain", }} />}
                        </div>
                    )
                })}
            </div>

            <div
                style={{
                    width: "100%",
                    height: "35%",
                    position: "absolute",
                    zIndex: 1,
                    bottom: 0,
                }}
            >
                <LinearGradient x2={1} y2={0} color1="#12121270" color2="#00000000" style={{}} />
            </div>

            <div
                style={{
                    width: "15%",
                    height: "100%",
                    position: "absolute",
                    zIndex: 1,
                    top: 0,
                }}
            >
                <LinearGradient x2={0} y2={1} color1="#12121270" color2="#00000000" style={{}} />
            </div>

            <div
                style={{
                    width: "15%",
                    height: "100%",
                    position: "absolute",
                    zIndex: 1,
                    top: 0,
                    right: 0,
                }}
            >
                <LinearGradient x2={0} y2={-1} color1="#12121270" color2="#00000000" style={{}} />
            </div>
            <div
                style={{
                    width: "15%",
                    height: "100%",
                    position: "absolute",
                    zIndex: 1,
                    top: 0,
                    left: 0,
                    justifyContent: "center",
                    zIndex: 2,
                    flexDirection: "row",
                    display: "flex",
                }}
            >
                <IconButton onClick={handlePrev} style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 0,
                    "-webkit-mask-image": "linear-gradient(to right, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 100%)",
                    "-webkit-mask-size": "100% 100%",
                    "-webkit-mask-repeat": "no-repeat",
                }}
                    TouchRippleProps={{
                        style: {
                            color: "rgba(255, 255, 255, 0.5)"
                        }
                    }}
                >
                    <ArrowBackIos />
                </IconButton>
            </div>

            <div
                style={{
                    width: "15%",
                    height: "100%",
                    position: "absolute",
                    zIndex: 1,
                    top: 0,
                    right: 0,
                    justifyContent: "center",
                    zIndex: 2,
                    flexDirection: "row",
                    display: "flex",
                }}
            >
                <IconButton onClick={handleNext} style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 0,
                    "-webkit-mask-image": "linear-gradient(to left, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 100%)",
                    "-webkit-mask-size": "100% 100%",
                    "-webkit-mask-repeat": "no-repeat",
                }}
                    TouchRippleProps={{
                        style: {
                            color: "rgba(255, 255, 255, 0.5)"
                        }
                    }}
                >
                    <ArrowBackIos style={{ transform: "rotate(180deg)" }} />
                </IconButton>
            </div>

            <div
                style={{
                    position: "absolute",
                    width: "100%",
                    height: "10%",
                    bottom: 0,
                    justifyContent: "center",
                    display: "flex",
                    zIndex: 2,
                }}
            >
                <LinearGradient x2={1} y2={0} color1="#12121270" color2="#00000000" style={{ position: "absolute" }} />
                <div
                    style={{
                        alignSelf: "center",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        zIndex: 2
                    }}
                >
                    {images.map((image, i) => (
                        <div
                            style={{
                                width: 15,
                                height: 15,
                                borderRadius: 10,
                                backgroundColor: index === i ? "#aaaaaa" : "#121212cc",
                                margin: 5,
                                cursor: "pointer"
                            }}
                            onClick={() => setIndex(i)}
                        ></div>
                    ))}
                </div>
            </div>
        </div>
    );
}

const GameCommentModal = ({ gameData, appID, versionID, editing, positive, ecomment = "", setGameData, isReply, replyData, commentData, isSub = false }) => {
    const [isLiked, setIsLiked] = useState(positive ? true : false);
    const [isDisliked, setIsDisliked] = useState(positive === false ? true : false);
    const [likeBouncing, setLikeBouncing] = useState(false);
    const [dislikeBouncing, setDislikeBouncing] = useState(false);
    const [comment, setComment] = useState(ecomment)

    const bounceAnimation = keyframes`
  0% { transform: scale(1) rotate(0deg); }
  50% { transform: scale(1.2) rotate(${isLiked ? 15 : -15}deg); }
  100% { transform: scale(1.2) rotate(0deg); }
`;

    const TransitionIconButton = styled(IconButton)(({ theme, selected, bouncing }) => ({
        transition: theme.transitions.create(['color', 'transform'], {
            duration: theme.transitions.duration.shortest,
        }),
        '&:hover': {
            transform: 'scale(1.2)',
        },
        animation: bouncing ? `${bounceAnimation} 0.5s ease` : 'none',
        transform: selected ? 'scale(1.2)' : 'scale(1)',
    }));

    const handleLikeClick = () => {
        setIsLiked(!isLiked);
        if (isDisliked) setIsDisliked(false);
        setLikeBouncing(true);
        setTimeout(() => setLikeBouncing(false), 500);
    };

    const handleDislikeClick = () => {
        setIsDisliked(!isDisliked);
        if (isLiked) setIsLiked(false);
        setDislikeBouncing(true);
        setTimeout(() => setDislikeBouncing(false), 500);
    };

    if (!replyData && commentData) replyData = commentData;

    return (
        <div>
            {(!isReply && !isSub) && <div><Typography
                variant="h6"
                component="p"
                sx={{
                    textAlign: "left"
                }}
            >Would you recommend this game to other players?</Typography>
                <div
                    style={{
                        display: "flex"
                    }}
                >
                    <TransitionIconButton bouncing={likeBouncing} selected={isLiked} color={isLiked ? 'primary' : 'default'} onClick={handleLikeClick}>
                        {isLiked ? <ThumbUp /> : <ThumbUpOutlined />}
                    </TransitionIconButton>

                    <TransitionIconButton bouncing={dislikeBouncing} selected={isDisliked} color={isDisliked ? 'primary' : 'default'} onClick={handleDislikeClick}>
                        {isDisliked ? <ThumbDown /> : <ThumbDownOutlined />}
                    </TransitionIconButton>
                </div></div>}

            <div
                style={{
                    transition: "500ms"
                }}
            >
                <div
                    style={{
                        height: isReply || isSub || (isLiked || isDisliked) ? "auto" : 0,
                        overflow: "hidden",
                    }}
                >
                    <Typography
                        variant="h6"
                        component="p"
                        sx={{
                            textAlign: "left"
                        }}
                    >Leave a {isReply ? `Reply to ${replyData.user.username}` : "Review"}</Typography>

                    <FormControl
                        sx={{
                            width: "100%"
                        }}
                    >
                        <TextField
                            sx={{
                                marginTop: 2,
                            }}
                            label="Comment"
                            multiline
                            minRows={3}
                            fullWidth
                            inputProps={{ maxLength: 2000, minLength: 16 }}
                            helperText={`${comment.length}/2000`}
                            variant="outlined"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </FormControl>

                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            marginTop: 3
                        }}
                        onClick={() => {
                            if (!localStorage.getItem("token")) {
                                snackCaller("You need to be logged in to comment games.")
                                navigate("/login")
                                return
                            }

                            util.postRequest("/api/games/comment", {
                                appID,
                                versionID,
                                positive: isReply ? null : isLiked,
                                comment,
                                parentComment: replyData?.UUID || null,
                                CUUID: editing && !isReply ? commentData.UUID : null
                            }, null, 'application/json', true).then((res) => {
                                if (res.redirect) {
                                    hideModal()
                                    util.postRequest('/api/games/gameMeta', { appID, versionID })
                                        .then(data => {
                                            setGameData(data);
                                        })
                                }
                            })
                        }}
                    >Send</Button>

                    <Typography variant="p" component="p" sx={{ textAlign: "left", marginTop: 2, opacity: 0.6, fontSize: 12 }}>Your {isReply ? "Reply" : "Review"} will be visible to everyone. Please make sure to be polite and constructive. {isReply ? "" : "You can only send 1 review per game."}</Typography>
                </div>
            </div>
        </div>
    )
}

const Home = () => {
    const navigate = useNavigate();
    const [gameData, setGameData] = useState({});
    const appID = window.location.hash.split("/")[2];
    const versionID = window.location.hash.split("/")[3];
    const [ratingColor, setRatingColor] = useState("#ababab")
    const [rgbaColor, setRgbaColor] = useState({ r: 0, g: 0, b: 0, a: 0 });
    const [currentCommentPage, setCurrentCommentPage] = useState(0)
    const [commentCount, setCommentCount] = useState(0)
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user") || "false") || {});
    const [commentSorting, setCommentSorting] = useState(1);
    const [isBounce, setIsBounce] = useState(null);
    const [tagsModal, setTagsModal] = useState(false);
    const [carouselWidth, setCarouselWidth] = useState(0);
    const isBounceRef = useRef(isBounce);
    const [contentWarning, setContentWarning] = useState(false);
    const [warnedTags, setWarnedTags] = useState([])

    useEffect(() => {
        isBounceRef.current = isBounce
    }, [isBounce]);

    useEffect(() => {
        let bouncer = setTimeout(() => {
            setIsBounce(true)

            util.analyticsHandler("gamePageVisit", {
                game: parseInt(appID),
                version: parseInt(versionID),
            })
        }, 250)

        util.postRequest('/api/games/gameMeta', { appID, versionID })
            .then(data => {
                let contentWarnableTags = ["loli", "scat"].concat(user?.dislikedTags)
                contentWarnableTags = contentWarnableTags.map(t => t?.toLowerCase())
                let foundWarnables = []

                // check if gameData.version.tags includes any tags from the warnables
                // check tags by name, .toLowerCase() and .replace(" ", "")

                data.version?.tags?.forEach(tag => {
                    let ntag = tag?.toLowerCase().replace(" ", "")
                    console.log(ntag, contentWarnableTags)
                    if (contentWarnableTags.includes(ntag)) {
                        foundWarnables.push(tag)
                    }
                })

                if (foundWarnables.length > 0) {
                    setContentWarning(true)
                    setWarnedTags(foundWarnables)
                } else {
                    setContentWarning(false)
                    setWarnedTags([])
                }

                setGameData(data);
                setCommentCount(data.game.comments?.length)
                if (data.game?.verdict != null) {
                    let color;
                    if (data.game.verdict < -80) {
                        color = "#ff0000"; // Deep red for very negative
                    } else if (data.game.verdict < -60) {
                        color = "#ff5500"; // Darker orange for negative
                    } else if (data.game.verdict < -40) {
                        color = "#ffaa00"; // Orange for slightly negative
                    } else if (data.game.verdict < 0) {
                        color = "#ffd700"; // Yellow for slightly below neutral
                    } else if (data.game.verdict === 0) {
                        color = "#ababab"; // Grey for neutral
                    } else if (data.game.verdict < 20) {
                        color = "#9acd32"; // Yellow-green for slightly above neutral
                    } else if (data.game.verdict < 40) {
                        color = "#00ff00"; // Green for positive
                    } else if (data.game.verdict < 60) {
                        color = "#32cd32"; // Lime green for more positive
                    } else if (data.game.verdict < 80) {
                        color = "#228b22"; // Forest green for very positive
                    } else {
                        color = "#008000"; // Dark green for extremely positive
                    }

                    setRatingColor(color);
                    let rgb = hexToRgb(color);
                    setRgbaColor(`rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.5)`);
                }
            });

        let listener = () => {
            setCarouselWidth(document.getElementById("carousel")?.clientWidth)
        }

        window.addEventListener("resize", listener)

        setTimeout(() => {
            setCarouselWidth(document.getElementById("carousel")?.clientWidth)
        }, 500);

        return () => {
            clearTimeout(bouncer)
            window.removeEventListener("resize", listener)

            if (isBounceRef.current !== null)

                util.analyticsHandler("gamePageLeave", {
                    game: parseInt(appID),
                    version: parseInt(versionID),
                    isBounce: isBounceRef.current
                })

            console.log("UNMOUNTING!!!", isBounceRef.current)
        }
    }, []);

    if (contentWarning) {
        return (<Box
            sx={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: 2,

                }}
            >
                <Tooltip title="Click to view source" arrow>
                    <img
                        src={require("../images/cw.webp")} style={{ width: 200, height: 200, marginBottom: 20, cursor: "pointer", alignSelf: "center" }}
                        onClick={() => {
                            // open the image source in a new tab
                            window.open("https://gelbooru.com/index.php?page=post&s=view&id=7430454", "_blank")
                        }}
                    />
                </Tooltip>
                <Typography
                    variant="h3"
                    sx={{ textAlign: "left" }}
                >
                    Content Warning
                </Typography>

                <Typography
                    variant="h5"
                    sx={{
                        textAlign: "left",
                        color: "#ccc"
                    }}
                >
                    This game contains content that you might not like. These tags caused this warning:
                </Typography>

                <Typography
                    variant="h5"
                    sx={{
                        textAlign: "left",
                        color: "#eee"
                    }}
                >
                    <Markdown
                        markdown={warnedTags.map(t => `* ${t}`).join("\n")}
                    />
                </Typography>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 10,
                    }}
                >

                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => {
                            setContentWarning(false)
                        }}
                    >
                        Proceed, I understand
                    </Button>

                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => {
                            // eslint-disable-next-line no-restricted-globals
                            history.back()
                        }}
                    >
                        Go Back
                    </Button>

                </Box>
            </Box>
        </Box>
        )
    }

    return (
        <div style={{
            padding: 35
        }}>
            <Box
                sx={{
                }}
            >
                {gameData.game && (
                    <div>
                        <Typography variant="p" component="h1" sx={{ textAlign: "left" }}>{gameData.game.name}</Typography>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <div
                                style={{
                                    width: "75%"
                                }}
                            >
                                <Carousel adminMode={user.rank >= 4} setIsBounce={setIsBounce} setGameData={setGameData} appID={appID} versionID={versionID} gameData={gameData} images={gameData.version.images} />
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        marginTop: 20,
                                        width: carouselWidth
                                    }}
                                >
                                    <Tooltip title={gameData.game.isFollowed ? "Unfollow" : "Follow"} arrow>
                                        <IconButton color={"secondary"} style={{ marginRight: 10 }} onClick={() => {
                                            if (!localStorage.getItem("token")) {
                                                snackCaller("You need to be logged in to follow games.")
                                                navigate("/login")
                                                return
                                            }
                                            setIsBounce(false)
                                            util.postRequest("/api/games/follow", { appID }, () => {
                                                util.postRequest('/api/games/gameMeta', { appID, versionID })
                                                    .then(data => {
                                                        setGameData(data);
                                                        if (data.game?.verdict != null) {
                                                            let color;
                                                            if (data.game.verdict < -80) {
                                                                color = "#ff0000"; // Deep red for very negative
                                                            } else if (data.game.verdict < -60) {
                                                                color = "#ff5500"; // Darker orange for negative
                                                            } else if (data.game.verdict < -40) {
                                                                color = "#ffaa00"; // Orange for slightly negative
                                                            } else if (data.game.verdict < 0) {
                                                                color = "#ffd700"; // Yellow for slightly below neutral
                                                            } else if (data.game.verdict === 0) {
                                                                color = "#ababab"; // Grey for neutral
                                                            } else if (data.game.verdict < 20) {
                                                                color = "#9acd32"; // Yellow-green for slightly above neutral
                                                            } else if (data.game.verdict < 40) {
                                                                color = "#00ff00"; // Green for positive
                                                            } else if (data.game.verdict < 60) {
                                                                color = "#32cd32"; // Lime green for more positive
                                                            } else if (data.game.verdict < 80) {
                                                                color = "#228b22"; // Forest green for very positive
                                                            } else {
                                                                color = "#008000"; // Dark green for extremely positive
                                                            }

                                                            setRatingColor(color);
                                                            let rgb = hexToRgb(color);
                                                            setRgbaColor(`rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.5)`);
                                                        }
                                                    })
                                            })
                                        }}>{gameData.game.isFollowed ? <NotificationsActive /> : <Notifications />}</IconButton>
                                    </Tooltip>

                                    <Tooltip title={gameData.game.isIgnored ? "Unignore" : "Ignore"} arrow>
                                        <IconButton color={"secondary"} style={{ marginRight: 10 }} onClick={() => {
                                            if (!localStorage.getItem("token")) {
                                                snackCaller("You need to be logged in to ignore games.")
                                                navigate("/login")
                                                return
                                            }

                                            util.postRequest("/api/games/ignore", { appID }, () => {
                                                util.postRequest('/api/games/gameMeta', { appID, versionID })
                                                    .then(data => {
                                                        setGameData(data);
                                                        if (data.game?.verdict != null) {
                                                            let color;
                                                            if (data.game.verdict < -80) {
                                                                color = "#ff0000"; // Deep red for very negative
                                                            } else if (data.game.verdict < -60) {
                                                                color = "#ff5500"; // Darker orange for negative
                                                            } else if (data.game.verdict < -40) {
                                                                color = "#ffaa00"; // Orange for slightly negative
                                                            } else if (data.game.verdict < 0) {
                                                                color = "#ffd700"; // Yellow for slightly below neutral
                                                            } else if (data.game.verdict === 0) {
                                                                color = "#ababab"; // Grey for neutral
                                                            } else if (data.game.verdict < 20) {
                                                                color = "#9acd32"; // Yellow-green for slightly above neutral
                                                            } else if (data.game.verdict < 40) {
                                                                color = "#00ff00"; // Green for positive
                                                            } else if (data.game.verdict < 60) {
                                                                color = "#32cd32"; // Lime green for more positive
                                                            } else if (data.game.verdict < 80) {
                                                                color = "#228b22"; // Forest green for very positive
                                                            } else {
                                                                color = "#008000"; // Dark green for extremely positive
                                                            }

                                                            setRatingColor(color);
                                                            let rgb = hexToRgb(color);
                                                            setRgbaColor(`rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.5)`);
                                                        }
                                                    })
                                            })
                                        }}>{gameData.game.isIgnored ? <VisibilityOff /> : <Visibility />}</IconButton>
                                    </Tooltip>

                                    <Tooltip title={"Report"} arrow>
                                        <IconButton color={"secondary"} style={{ marginRight: 10 }} onClick={() => {
                                            if (!localStorage.getItem("token")) {
                                                snackCaller("You need to be logged in to report games.")
                                                navigate("/login")
                                                return
                                            }

                                            callReportModal({
                                                type: "game",
                                                title: gameData.game.name,
                                                id: appID
                                            })
                                        }}><Flag /></IconButton>
                                    </Tooltip>

                                    <Tooltip title={"Share"} arrow>
                                        <IconButton color={"secondary"} style={{ marginRight: 10 }} onClick={() => {
                                            navigator.clipboard.writeText(`https://vorecade.com/#/games/${gameData.game.appID}`)
                                            snackCaller("Link Copied!")
                                        }}><Share /></IconButton>
                                    </Tooltip>

                                    {
                                        gameData.version.supportPage &&
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            startIcon={<VolunteerActivism />}
                                            sx={{
                                            }}
                                            onClick={() => {
                                                setIsBounce(false)
                                                // set clipboard
                                                window.open(gameData.version.supportPage, "_blank")
                                            }}
                                        >
                                            Support the Developer
                                        </Button>}
                                </div>

                                <div
                                    style={{
                                        textAlign: "left",
                                    }}
                                >
                                    <Markdown markdown={gameData.version.description} />
                                </div>

                            </div>
                            <div style={{ width: "25%", marginLeft: 20 }}>
                                {
                                    // 350x175
                                }
                                <Image src={gameData.version.thumbnail} style={{ width: "100%", height: 175, objectFit: "cover", display: "flex", flexDirection: "column" }} />
                                <Typography variant="h6" component="p" sx={{ textAlign: "left" }}>Reviews: <Typography sx={{ color: ratingColor, textShadow: `0 0 10px ${rgbaColor}, 0 0 20px ${rgbaColor}, 0 0 30px ${rgbaColor}, 0 0 40px ${rgbaColor}` }}>{getRatingState(gameData.game.verdict)}</Typography></Typography>
                                <Typography variant="h6" component="p" sx={{ textAlign: "left", flexWrap: "wrap", wordBreak: "break-word", fontSize: 14 }}>{gameData.version.shortDescription}</Typography>
                                <Typography variant="p" component="p" sx={{ textAlign: "left" }}>Release Date: {new Date(gameData.game.created).toLocaleDateString(undefined, { day: "numeric", month: "short", year: "numeric" })}</Typography>
                                <Typography variant="p" component="p" sx={{ textAlign: "left" }}>Last Update: {new Date(gameData.version.created).toLocaleDateString(undefined, { day: "numeric", month: "short", year: "numeric" })}</Typography>
                                <Typography variant="p" component="p" sx={{ textAlign: "left" }}>Developer: <Typography onClick={() => {
                                    setIsBounce(false)
                                    navigate(`/profile/${gameData.game.developer.UUID}`)
                                }} variant="a" color="secondary" sx={{ cursor: "pointer" }} href="">{gameData.game.developer.username}</Typography></Typography>
                                <div
                                    style={{
                                        marginTop: 10
                                    }}
                                >
                                    <Typography variant="p" component="p" sx={{ textAlign: "left" }}>Developer added tags:</Typography>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            gap: 6
                                        }}
                                    >
                                        {gameData.version.tags.slice(0, 5).map(tag => {
                                            return (
                                                <Button variant="contained" color="primary" sx={{ marginRight: 1, padding: 0, paddingRight: 1, paddingLeft: 1 }}
                                                    onClick={() => {
                                                        navigate("/tags/" + encodeURIComponent(tag))
                                                    }}
                                                >{tag}</Button>
                                            )
                                        })}
                                        <Button onClick={() => {
                                            setIsBounce(false)
                                            setTagsModal(true)
                                        }} variant="text" color="primary" sx={{ marginRight: 1, padding: 0, fontSize: 12 }}>See All</Button>

                                        <Dialog
                                            open={tagsModal}
                                            onClose={() => setTagsModal(false)}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title">{"Tags"}</DialogTitle>
                                            <DialogContent
                                                sx={{
                                                    display: "flex",
                                                    gap: 1,
                                                    flexWrap: "wrap"
                                                }}
                                            >
                                                {gameData.version.tags.map(tag => {
                                                    return (
                                                        <Button variant="contained" color="primary" sx={{ padding: 0, paddingRight: 1, paddingLeft: 1 }}
                                                            onClick={() => {
                                                                navigate("/tags/" + encodeURIComponent(tag))
                                                            }}
                                                        >{tag}</Button>
                                                    )
                                                })}
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={() => setTagsModal(false)} autoFocus>
                                                    Close
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </div>
                                    {
                                        gameData.game.developer.UUID === user.UUID &&
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            sx={{
                                                marginTop: 2
                                            }}
                                            onClick={() => {
                                                setIsBounce(false)
                                                // set clipboard
                                                navigator.clipboard.writeText(`https://vorecade.com/api/games/pel/${appID}`)
                                                snackCaller("Permanent Executable Link copied to clipboard")
                                            }}
                                        >
                                            Copy Permanent Executable Link
                                        </Button>}
                                </div>
                            </div>
                        </div>

                        <div>
                            <Typography
                                variant="h5"
                                component="p"
                                sx={{
                                    textAlign: "left",
                                    marginTop: 10
                                }}
                            >Player Reviews</Typography>

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginTop: 10,
                                    backgroundColor: "#202020",
                                    padding: 10,
                                    width: "100%",
                                    borderRadius: 4,
                                    height: 50
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    component="p"
                                    sx={{
                                        textAlign: "left",
                                        width: "50%"
                                    }}
                                >Overall Reviews</Typography>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        width: "50%"
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        component="p"
                                        sx={{
                                            textAlign: "left",
                                            color: ratingColor,
                                            textShadow: `0 0 10px ${rgbaColor}, 0 0 20px ${rgbaColor}, 0 0 30px ${rgbaColor}, 0 0 40px ${rgbaColor}`
                                        }}
                                    >{getRatingState(gameData.game.verdict)}</Typography>
                                </div>

                                <Typography
                                    variant="h6"
                                    component="p"
                                    sx={{
                                        textAlign: "left",
                                        width: "50%"
                                    }}
                                >Recent Reviews</Typography>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        width: "50%"
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        component="p"
                                        sx={{
                                            textAlign: "left",
                                            color: ratingColor,
                                            textShadow: `0 0 10px ${rgbaColor}, 0 0 20px ${rgbaColor}, 0 0 30px ${rgbaColor}, 0 0 40px ${rgbaColor}`
                                        }}
                                    >{getRatingState(gameData.game.verdict)}</Typography>
                                </div>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "column",
                                    marginTop: 10,
                                    backgroundColor: "#202020",
                                    padding: 10,
                                    width: "100%",
                                    borderRadius: 4,
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    component="p"
                                    sx={{
                                        textAlign: "left",
                                        width: "100%",
                                        marginBottom: 5
                                    }}
                                >Comments</Typography>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "100%"
                                    }}
                                >
                                    {gameData.game.comments.length === 0 ?
                                        <Box
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                gap: 15,
                                            }}
                                        >
                                            <Typography variant="h6" component="p" sx={{}}>No comments... But you can change that!</Typography>
                                            {gameData.game.isOwned ?
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        setIsBounce(false)
                                                        callGenericModal("Write a Comment",
                                                            <GameCommentModal
                                                                appID={appID}
                                                                versionID={versionID}
                                                                gameData={gameData}
                                                                setGameData={setGameData}
                                                            />
                                                            , [])
                                                    }}
                                                >Add Comment</Button>
                                                :
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        snackCaller("You must own the game to leave a comment")
                                                    }}
                                                >Add Comment</Button>
                                            }
                                        </Box>
                                        :
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: -50,
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: 10
                                            }}
                                        >
                                            {gameData.game.comments.slice(currentCommentPage * 20, currentCommentPage * 20 + 20).map(comment => <Comment setIsBounce={setIsBounce} navigate={navigate} p={comment} setGameData={setGameData} gameData={gameData} user={user} comment={comment} />)}
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{
                                                    display: "none"
                                                }}
                                                onClick={() => {
                                                    setIsBounce(false)
                                                    callGenericModal("Comments", gameData.game.comments.map(comment => {
                                                        return (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    backgroundColor: "#202020",
                                                                    padding: 10,
                                                                    borderRadius: 4,
                                                                    marginTop: 10
                                                                }}
                                                            >
                                                                <Comment setIsBounce={setIsBounce} navigate={navigate} setGameData={setGameData} gameData={gameData} user={user} comment={comment} />
                                                            </div>
                                                        )
                                                    }), [])
                                                }}
                                            >See All</Button>
                                            <Pagination sx={{
                                                margin: 2,
                                                display: "flex",
                                                justifyContent: "center"
                                            }} onChange={(ev, val) => {
                                                setCurrentCommentPage(val - 1)
                                            }} color="secondary" count={commentCount / 20 < 1 ? 1 : Math.trunc(commentCount / 20)} variant="outlined" />
                                            {gameData.game.comments.find(c => c.user.UUID === user.UUID) ?
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        setIsBounce(false)
                                                        callGenericModal("Edit your Comment",
                                                            <GameCommentModal
                                                                appID={gameData.game.appID}
                                                                versionID={gameData.version.versionID}
                                                                gameData={gameData}
                                                                ecomment={gameData.game.comments.find(c => c.user.UUID === user.UUID).comment}
                                                                commentData={gameData.game.comments.find(c => c.user.UUID === user.UUID)}
                                                                positive={gameData.game.comments.find(c => c.user.UUID === user.UUID).positive}
                                                                editing={true}
                                                                setGameData={setGameData}
                                                            />
                                                            , [])
                                                    }}
                                                >Edit Comment</Button>
                                                :
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        setIsBounce(false)
                                                        callGenericModal("Write a Comment",
                                                            <GameCommentModal
                                                                appID={appID}
                                                                versionID={versionID}
                                                                gameData={gameData}
                                                                setGameData={setGameData}
                                                            />
                                                            , [])
                                                    }}
                                                >Add Comment</Button>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Box>

        </div>
    );
};

export default Home;