import { Typography, Box, ButtonBase, FormControl, TextField, MenuItem, FormGroup, FormControlLabel, Checkbox, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import { Component, useState } from 'react';
import { Form, Navigate, useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Add, CheckBox, Close, Remove } from '@mui/icons-material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import MarkdownEditor from '../../components/markdownEditor';
import util from '../../util';
import JSConfetti from 'js-confetti'
import { loadingCaller } from '../../App';
import Image from '../../components/Image';

function isURL(url) {
    try {
        new URL(url);
        return true;
    } catch (err) {
        return false;
    }
}

const confetti = new JSConfetti()

const steps = ["Binary", "Game Details", "Game Page"];

const filter = createFilterOptions()

class MediaPane extends Component {
    constructor(props) {
        super(props)

        this.state = {
            media: null,
            randInputID: Math.random().toString(36).substring(7),
            isVideo: false,
            rem: false
        }
    }

    handleSelectClick = () => {
        document.getElementById(this.state.randInputID).click();
    }

    componentDidUpdate() {
        if (this.props.forceSelected && !this.state.media && !this.state.rem) {
            let media = this.props.forceSelected
            if (!media) return

            this.setState({
                media: media.file,
                isVideo: media.isVideo,
            })
        }
    }

    render() {
        return (
            <div
                style={{
                    position: "relative",
                }}
            >
                {this.state.media && <IconButton
                    sx={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        zIndex: 1000,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        backdropFilter: "blur(5px)",
                        borderRadius: 0,
                        borderBottomLeftRadius: 10,
                        borderTopRightRadius: 10,
                    }}
                    onClick={() => {
                        let cl = JSON.parse(JSON.stringify(this.props.that.state.media))
                        cl = cl.filter((media) => media.mID !== this.props.mID)
                        let newM = cl

                        this.props.that.setState({
                            media: newM,
                        }, () => {
                            this.props.that.setState({
                                nextButton: this.props.that.checkValidity()
                            })
                        })

                        this.setState({
                            media: null,
                            isVideo: false,
                            rem: true
                        })
                    }}
                >
                    <Close />
                </IconButton>}

                <ButtonBase
                    style={{
                        width: 250,
                        height: 150,
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        borderRadius: 10,
                        margin: 10,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        position: "relative",
                        overflow: "hidden"
                    }}

                    onClick={async () => {
                        let excludeVideos = this.props.isThumbnail || this.props.that.media?.filter(m => m.isVideo).length >= 3 ? [] : ["mp4", "mkv", "webm"]
                        let file = await window.ipcRenderer.invoke("selectFile", {
                            extensions: ["png", "jpg", "jpeg", "jfif", "webp"].concat(excludeVideos),
                            singleFile: true
                        })

                        if (!file) return

                        let isVideo = file.filePath.endsWith(".mp4") || file.filePath.endsWith(".mkv") || file.filePath.endsWith(".webm")
                        let ext = file.filePath.split(".").pop()

                        let mimeType;
                        if (isVideo) {
                            switch (ext) {
                                case 'mp4':
                                    mimeType = 'video/mp4';
                                    break;
                                case 'mkv':
                                    mimeType = 'video/x-matroska';
                                    break;
                                case 'webm':
                                    mimeType = 'video/webm';
                                    break;
                                default:
                                    mimeType = 'application/octet-stream'; // fallback MIME type
                            }
                        } else {
                            mimeType = 'image/png';
                        }

                        file.file = `data:${mimeType};base64,` + file.file
                        file.selectorID = this.state.randInputID
                        file.thumbnail = this.props.isThumbnail
                        file.isVideo = isVideo
                        file.mID = this.props.mID

                        this.setState({
                            media: file.file,
                            isVideo: isVideo
                        })

                        // Update media state in the parent component
                        let updatedMedia = [...this.props.that.state.media];
                        const existingIndex = updatedMedia.findIndex(m => m.mID === this.props.mID);
                        if (existingIndex !== -1) {
                            updatedMedia[existingIndex] = file; // Update existing media
                        } else {
                            updatedMedia.push(file); // Add new media
                        }

                        this.props.that.setState({
                            media: updatedMedia,
                            isVideo: isVideo,
                            thumbnail: this.props.isThumbnail,
                            fileName: file.filePath,
                        }, () => {
                            this.props.that.setState({
                                nextButton: this.props.that.checkValidity()
                            })
                        })
                    }}
                >
                    <input
                        type="file"
                        accept={this.props.isThumbnail ? "image/*" : "image/*, video/*"}
                        id={this.state.randInputID}
                        style={{
                            display: "none"
                        }}
                        onChange={(ev) => {
                            if (ev.target.files && ev.target.files[0]) {
                                const file = ev.target.files[0];
                                const blobURL = URL.createObjectURL(file);
                                const isVideo = file.type.startsWith('video/');
                                this.setState({ media: blobURL, isVideo: isVideo });

                                let setData = {
                                    media: file,
                                    isVideo: isVideo,
                                    thumbnail: this.props.isThumbnail,
                                    mID: this.props.mID,
                                    fileName: file.name
                                }

                                // Update media state in the parent component
                                let updatedMedia = [...this.props.that.state.media];
                                const existingIndex = updatedMedia.findIndex(m => m.mID === this.props.mID);
                                if (existingIndex !== -1) {
                                    updatedMedia[existingIndex] = setData; // Update existing media
                                } else {
                                    updatedMedia.push(setData); // Add new media
                                }

                                this.props.that.setState({ media: updatedMedia }, () => {
                                    this.props.that.setState({
                                        nextButton: this.props.that.checkValidity()
                                    })
                                })
                            }
                        }}
                    />

                    {!this.state.media ?
                        <>
                            <Add
                                sx={{
                                    fontSize: 70
                                }}
                            />

                            <Typography
                                style={{
                                    color: "#fff",
                                    fontSize: 25
                                }}
                            >
                                Add {this.props.isThumbnail ? "Thumbnail" : "Media"}
                            </Typography>
                        </>
                        :
                        this.state.isVideo ? (
                            <video
                                src={this.state.media}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover"
                                }}
                                autoPlay={true}
                                controls={false}
                                muted
                                loop
                            />
                        ) : (
                            <img
                                src={this.state.media}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover"
                                }}
                            />
                        )
                    }
                </ButtonBase>
            </div>
        )
    }
}

export default class createGame extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeStep: 0,
            skipped: new Set(),
            nextButton: false,
            game: {
                name: "",
                platform: "",
                version: "",
                shortDescription: "",
                changelog: "",
                description: "",
                executableName: "",
                binary: null,
                preCompressSize: 0,
                estimatedCompressSize: 0,
                entropy: 100,
                requiredUpdate: false,
                allowAnonymous: true,
                delisted: false,
                supportPage: ""
            },
            tagOptions: [],
            tags: [],
            shouldCheck: false,
            media: [],
            interval: null,
            ffmpegReady: false,
            goBack: false,
            appID: window.location.hash.split("/")[2],
            versions: [],
            versionSelector: false,
            selectedVersion: null
        }
    }

    componentDidMount() {
        window.onbeforeunload = (e) => {
            e.preventDefault();
            e.returnValue = '';
        };

        (async () => {
            let isSucceeded = await window.ipcRenderer.invoke("initializeFFMPEG", {})

            console.log(`FFMPEG initialization: ${isSucceeded ? "successful" : "error"}`)

            if (!isSucceeded) {
                alert("Failed to initialize FFMPEG, please try again later")

                this.setState({
                    goBack: true
                })
                return
            }

            loadingCaller(true, "Loading Data...")

            let gameData = (await util.postRequest("/api/games/getGameEditData", { appID: this.state.appID })).game

            this.setState({
                versions: gameData.versions,
                game: {
                    ...this.state.game,
                    name: gameData.name,
                    platform: gameData.platform,
                    delisted: gameData.delisted,
                },
                versionSelector: true
            })

            loadingCaller(false)

            this.setState({
                ffmpegReady: isSucceeded
            })
        })()
    }

    componentWillUnmount() {
        window.onbeforeunload = null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.activeStep !== nextState.activeStep) {
            this.setState({ shouldCheck: true })
        }

        return true;
    }

    componentDidUpdate() {
        if (this.state.shouldCheck) {
            this.setState({ nextButton: this.checkValidity(), shouldCheck: false })
        }
    }

    checkValidity() {
        console.log("CHECKING VALIDITIY")
        switch (this.state.activeStep) {
            case 0:
                return this.state.game.executableName !== "" && this.state.game.version !== ""
            case 1:
                if (this.state.game.supportPage && !isURL(this.state.game.supportPage)) {
                    return false
                }
                return this.state.game.shortDescription.length > 8 && this.state.game.version !== "" && this.state.tags.length > 2;
            case 2:
                let thumbExists = this.state.media.filter((media) => media?.thumbnail).length > 0;
                let mediaExists = this.state.media.filter((media) => !media?.thumbnail).length > 2;
                let descExists = this.state.game.description.length > 30;
                return thumbExists && mediaExists && descExists;
            default:
                return false;
        }
    }

    render() {
        const state = this.state
        const isStepOptional = (step) => {
            return false
        };

        const handleNext = () => {
            this.setState({ activeStep: this.activeStep + 1 });
        };

        const handleBack = () => {
            this.setState({ activeStep: this.activeStep - 1 });
        };


        const handleReset = () => {
            this.setState({ activeStep: 0 });
        };

        return (
            <Box sx={{ width: '100%', marginTop: 3, height: "100%" }}>
                <Dialog
                    open={this.state.versionSelector}
                >
                    <DialogTitle>
                        Select Version
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please select the version of the game you would like to base your new version from.
                        </DialogContentText>
                        <FormControl sx={{
                            mt: 1
                        }} fullWidth>
                            <TextField
                                select
                                label="Version"
                                value={this.state.selectedVersion}
                                onChange={(ev) => {
                                    console.log(ev.target.value)
                                    this.setState({
                                        game: {
                                            ...ev.target.value,
                                            oldVersion: ev.target.value.version,
                                            delisted: this.state.game.delisted
                                        },
                                        tags: ev.target.value.tags.map((tag) => tag.name),
                                        selectedVersion: ev.target.value,
                                        media: ev.target.value.images.map((media, i) => {
                                            return {
                                                thumbnail: false,
                                                file: media.url,
                                                isVideo: media.url.endsWith(".mp4"),
                                                mID: i
                                            }
                                        }).concat({
                                            thumbnail: true,
                                            file: ev.target.value.thumbnail.url,
                                            isVideo: ev.target.value.thumbnail.url.endsWith(".mp4")
                                        })
                                    })
                                }}
                            >
                                <MenuItem disabled value="">
                                    <em>Select</em>
                                </MenuItem>
                                {this.state.versions.reverse().map((version) => (
                                    <MenuItem key={version.versionID} value={version}>
                                        {version.version} (v{version.versionID}) - {new Date(version.created).toLocaleDateString()}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ versionSelector: false, goBack: true })}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.setState({ versionSelector: false }, () => {
                            this.setState({
                                nextButton: this.checkValidity()
                            })
                        })}>
                            Continue
                        </Button>
                    </DialogActions>
                </Dialog>
                {this.state.goBack && <Navigate to="/contentManager" />}
                <Stepper activeStep={this.state.activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepOptional(index)) {
                            labelProps.optional = (
                                <Typography variant="caption">Optional</Typography>
                            );
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {this.state.activeStep === steps.length ? (
                    <div>
                        <Typography sx={{ mt: 4, mb: 1, fontSize: 50 }}>
                            All Done!
                        </Typography>

                        <Typography sx={{ mt: 2, mb: 1, fontSize: 20 }}>
                            Your game has been successfully uploaded!
                        </Typography>

                        <Typography sx={{ mt: 2, mb: 1, fontSize: 16 }}>
                            You'll be able to see your game in your content manager.
                        </Typography>

                        {this.state.go && <Navigate to="/contentManager" />}

                        <Button
                            variant="contained"
                            onClick={() => {
                                this.setState({ go: true })
                            }}
                            sx={{
                                mt: 2,
                                mb: 5
                            }}
                        >
                            Go to Content Manager
                        </Button>
                    </div>
                ) : (
                    <Box
                        style={{
                            paddingLeft: 24,
                            paddingRight: 24,
                            maxWidth: "xs",
                            width: "100%",

                        }}
                    >
                        <Box
                            sx={{
                                marginTop: 5,
                                position: "relative",
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                flexDirection: "column",
                            }}
                        >
                            {this.state.activeStep === 0 && <Box>
                                <FormControl
                                    fullWidth
                                    sx={{
                                        mt: 1,
                                        justifyContent: "flex-start",
                                        width: "100%",
                                        gap: 3,
                                        display: "flex",
                                        flexDirection: "row"
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: 2
                                        }}
                                    >
                                        <TextField
                                            label="Executable Name"
                                            variant="outlined"
                                            disabled={this.state.game.binary === null}
                                            sx={{
                                                width: 250,
                                            }}
                                            fullWidth
                                            required
                                            onChange={(ev) => {
                                                this.setState({
                                                    game: {
                                                        ...this.state.game,
                                                        executableName: ev.target.value,
                                                    }
                                                }, () => {
                                                    this.setState({
                                                        nextButton: this.checkValidity()
                                                    })
                                                })
                                            }}
                                            value={this.state.game.executableName}
                                        />

                                        <Button
                                            variant="contained"

                                            sx={{
                                                width: 250,
                                            }}

                                            onClick={async () => {
                                                let folder = await window.ipcRenderer.invoke("selectFolder")
                                                if (!folder) return
                                                let folderData = await window.ipcRenderer.invoke("handleGameFolder", { folder: folder })
                                                loadingCaller(true, "Calculating Entropy...")
                                                let entropy = await window.ipcRenderer.invoke("calculateFolderEntropy", { folder: folder })
                                                loadingCaller(false)

                                                this.setState({
                                                    game: {
                                                        ...this.state.game,
                                                        executableName: folderData.autoDetectedEXERelativePath,
                                                        preCompressSize: (folderData.totalSize / 1024 / 1024).toFixed(0),
                                                        estimatedCompressSize: ((folderData.totalSize / 1024 / 1024) * entropy / 100).toFixed(0),
                                                        entropy: entropy,
                                                        binary: folder
                                                    },
                                                })
                                            }}
                                        >
                                            Select Game Directory
                                        </Button>

                                        <Typography
                                            sx={{
                                                textAlign: "left",
                                                fontSize: 12
                                            }}
                                        >
                                            Please correct the executable name if it is incorrect
                                        </Typography>

                                        <Typography
                                            sx={{
                                                textAlign: "left"
                                            }}
                                        >
                                            Game Size (Pre-compress): {this.state.game.preCompressSize} MB<br />
                                            Game Size (Estimated Compression): {this.state.game.estimatedCompressSize} MB
                                        </Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            flexDirection: "column"
                                        }}
                                    >
                                        <TextField
                                            label="Current Game Version"
                                            variant="outlined"
                                            sx={{
                                                width: 250,
                                            }}
                                            fullWidth
                                            required
                                            onChange={(ev) => {
                                                ev.target.value = ev.target.value.replace(/ /g, '')
                                                this.setState({
                                                    game: {
                                                        ...this.state.game,
                                                        version: ev.target.value,
                                                    }
                                                }, () => {
                                                    this.setState({
                                                        nextButton: this.checkValidity()
                                                    })
                                                })
                                            }}
                                            value={this.state.game.version}
                                        />

                                        <Typography
                                            sx={{
                                                textAlign: "left",
                                                fontSize: 12,
                                                maxWidth: 230,
                                                mt: 1
                                            }}
                                        >
                                            This is the version of the game that will be displayed on the game page.
                                        </Typography>
                                    </Box>

                                    <Box>
                                        <FormControlLabel sx={{
                                            mt: 0
                                        }} control={<Checkbox disabled={this.state.game.binary == null} checked={this.state.game.requiredUpdate} onChange={(ev) => {
                                            this.setState({
                                                game: {
                                                    ...this.state.game,
                                                    requiredUpdate: ev.target.checked
                                                }
                                            }, () => {
                                                this.setState({
                                                    nextButton: this.checkValidity()
                                                })
                                            });
                                        }} />} label="This is a required update" />

                                        <FormControlLabel sx={{
                                            mt: 0
                                        }} control={<Checkbox checked={this.state.game.delisted} onChange={(ev) => {
                                            this.setState({
                                                game: {
                                                    ...this.state.game,
                                                    delisted: ev.target.checked
                                                }
                                            }, () => {
                                                this.setState({
                                                    nextButton: this.checkValidity()
                                                })
                                            });
                                        }} />} label="Delist Game" />

                                        {false && <FormControlLabel control={<Checkbox checked={this.state.game.allowAnonymous} onChange={(ev) => {
                                            this.setState({

                                                game: {
                                                    ...this.state.game,
                                                    allowAnonymous: ev.target.checked
                                                }
                                            }, () => {
                                                this.setState({
                                                    nextButton: this.checkValidity()
                                                })
                                            })
                                        }} />} label="Allow Anonymous Vorecade Users" />}
                                    </Box>
                                </FormControl>
                            </Box>}
                            {this.state.activeStep === 1 && <Box
                                sx={{
                                    width: "100%"
                                }}
                            >
                                <FormControl
                                    fullWidth
                                    sx={{
                                        mt: 1,
                                        justifyContent: "flex-start",
                                        width: "100%",
                                        gap: 3,
                                        display: "flex",
                                        flexDirection: "row"
                                    }}
                                >
                                    <TextField
                                        label="Short Description"
                                        variant="outlined"
                                        multiline
                                        minRows={3}
                                        sx={{
                                            width: 250,
                                        }}
                                        fullWidth
                                        required
                                        onChange={(ev) => {
                                            this.setState({
                                                game: {
                                                    ...this.state.game,
                                                    shortDescription: ev.target.value
                                                }
                                            }, () => {
                                                this.setState({
                                                    nextButton: this.checkValidity()
                                                })
                                            })
                                        }}
                                        value={this.state.game.shortDescription}
                                        inputProps={{
                                            maxLength: 225
                                        }}
                                        helperText={`${this.state.game.shortDescription.length}/225`}
                                    />
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flex: 1,
                                            gap: 3
                                        }}
                                    >
                                        <Autocomplete
                                            multiple
                                            id="name"
                                            options={this.state.tagOptions} // This should be an array of possible tags
                                            freeSolo
                                            fullWidth
                                            onChange={(e, v) => {
                                                if (!v) return
                                                let val

                                                val = v.map((tag) => {
                                                    if (tag.name) {
                                                        return tag.name
                                                    } else {
                                                        return tag
                                                    }
                                                })

                                                this.setState({ tags: val }, () => {
                                                    this.setState({
                                                        nextButton: this.checkValidity()
                                                    })
                                                })
                                            }}
                                            sx={{
                                                width: 450
                                            }}
                                            getOptionLabel={(option) => option?.name || option}
                                            value={this.state.tags}
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);

                                                let { inputValue } = params;
                                                inputValue = inputValue.trim()
                                                // Suggest the creation of a new value
                                                const isExisting = options.some((option) => inputValue === option.name);
                                                if (inputValue !== '' && !isExisting) {
                                                    filtered.push({
                                                        inputValue,
                                                        name: inputValue,
                                                    });
                                                }

                                                return filtered;
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    autoFocus
                                                    label="Tags"
                                                    type="text"
                                                    style={{
                                                    }}
                                                    fullWidth
                                                    onChange={(e) => {
                                                        util.postRequest("/api/tags/autocomplete", { query: e.target.value }).then((res) => {
                                                            console.log(res)
                                                            this.setState({ tagOptions: res })
                                                        })
                                                    }}
                                                />
                                            )}
                                        />
                                        <TextField
                                        label="Support Page"
                                        error={this.state.game.supportPage && !isURL(this.state.game.supportPage)}
                                        variant="outlined"
                                        sx={{
                                            width: 450,
                                        }}
                                        fullWidth
                                        onChange={(ev) => {
                                            this.setState({
                                                game: {
                                                    ...this.state.game,
                                                    supportPage: ev.target.value
                                                }
                                            }, () => {
                                                this.setState({
                                                    nextButton: this.checkValidity()
                                                })
                                            })
                                        }}
                                        value={this.state.game.supportPage}
                                        inputProps={{
                                            maxLength: 225
                                        }}
                                    />
                                    </Box>
                                    
                                </FormControl>
                                <div
                                    style={{
                                        width: "100%"
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            textAlign: "left"
                                        }}
                                        variant='h5'
                                        component="h2"
                                    >
                                        Changelog
                                    </Typography>
                                    <MarkdownEditor
                                        value={this.state.game.changelog}
                                        onChange={(ev) => {
                                            this.setState({
                                                game: {
                                                    ...this.state.game,
                                                    changelog: ev
                                                }
                                            }, () => {
                                                this.setState({
                                                    nextButton: this.checkValidity()
                                                })
                                            })
                                        }}
                                    />

                                </div>
                            </Box>}
                            <Box
                                sx={{
                                    display: this.state.activeStep === 2 ? "block" : "none",
                                }}
                            >
                                <Typography
                                    sx={{
                                        mt: 2,
                                        textAlign: "center"
                                    }}
                                    variant='h5'
                                    component="h2"
                                >
                                    Game Images / Videos
                                </Typography>

                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                    }}
                                >
                                    <MediaPane
                                        that={this}
                                        isThumbnail
                                        forceSelected={this.state.media.find((media) => media?.thumbnail)}
                                        mID={-1}
                                    />
                                </Box>
                                <Typography
                                    sx={{
                                        mt: 2,
                                        textAlign: "left"
                                    }}
                                    variant='h5'
                                    component="h4"
                                >
                                    Game Screenshots / Videos
                                    (Minimum 3 Items)
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: 2,
                                        width: "90vw",
                                        maxWidth: "1400px",
                                        overflowX: "auto",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: 2,
                                            position: "relative",
                                        }}
                                    >
                                        {/* Use map to dynamically render MediaPane components */}
                                        {Array.from({ length: 10 }).map((_, i) => (
                                            <MediaPane
                                                key={i}
                                                that={this}
                                                forceSelected={this.state.media.find((media) => !media?.thumbnail && media.mID === i)}
                                                mID={i}
                                            />
                                        ))}
                                    </Box>
                                </Box>

                                <Typography
                                    sx={{
                                        mt: 2,
                                        textAlign: "left"
                                    }}
                                    variant='h5'
                                    component="h2"
                                >
                                    Game Description
                                </Typography>
                                <MarkdownEditor
                                    value={this.state.game.description}
                                    onChange={(ev) => {
                                        this.setState({
                                            game: {
                                                ...this.state.game,
                                                description: ev
                                            }
                                        }, () => {
                                            this.setState({
                                                nextButton: this.checkValidity()
                                            })
                                        })
                                    }}
                                />
                            </Box>
                        </Box>
                        <Dialog
                            open={this.state.exitDialog}
                            onClose={() => this.setState({ exitDialog: false })}
                        >
                            <DialogTitle>
                                Are you sure you want to exit?
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    You will lose all progress if you exit now.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.setState({ exitDialog: false })}>
                                    Cancel
                                </Button>
                                <Button onClick={() => this.setState({ goBack: true })}>
                                    Exit
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, bottom: "2rem", width: "100%", justifyContent: "space-between" }}>
                            <Button
                                color="inherit"
                                onClick={() => {
                                    if (this.state.activeStep === 0) {
                                        this.setState({ exitDialog: true })
                                        return
                                    }
                                    this.setState({ activeStep: this.state.activeStep - 1 }, () => {
                                        this.setState({ nextButton: this.checkValidity() })
                                    })
                                }}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />

                            <Button disabled={!this.state.nextButton} onClick={async () => {
                                this.setState({ activeStep: this.state.activeStep + 1 }, () => {
                                    this.setState({ nextButton: this.checkValidity() })
                                })

                                if (this.state.activeStep === steps.length - 1) {
                                    loadingCaller(true, "Finishing up... Might take a while...")
                                    await window.ipcRenderer.invoke("gameSubmit", {
                                        game: this.state.game,
                                        tags: this.state.tags,
                                        media: this.state.media,
                                        appID: this.state.appID,
                                        isSoftUpdate: this.state.game.binary == null
                                    })

                                    loadingCaller(false)

                                    confetti.addConfetti({
                                        confettiColors: [
                                            '#ff0a54', '#ff477e', '#ff7096', '#ff85a1', '#fbb1bd', '#f9bec7',
                                        ],
                                    })
                                }
                            }}>
                                {this.state.activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </Box>
                    </Box>
                )}
            </Box>
        );
    }
}