import { Typography, Box, Select, MenuItem, Accordion, AccordionSummary, IconButton, Collapse, TextField, Dialog, DialogContent, DialogContentText, Checkbox, FormGroup, FormControl, FormControlLabel, Autocomplete, createFilterOptions, Tooltip, DialogActions } from '@mui/material';
import Button from '@mui/material/Button';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import util from '../../util';
import { ChartsReferenceLine, ChartsXAxis, ChartsYAxis, LineChart, LinePlot, MarkPlot } from '@mui/x-charts';
import { ArrowDropDown, ArrowDropUp, Check, Close } from '@mui/icons-material';
import { loadingCaller } from '../../App';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

// Utility functions to subtract years, months, weeks, and days from the current date
const subtractDate = (type, amount) => {
    const date = new Date();
    switch (type) {
        case 'years':
            date.setFullYear(date.getFullYear() - amount);
            break;
        case 'months':
            date.setMonth(date.getMonth() - amount);
            break;
        case 'weeks':
            date.setDate(date.getDate() - (amount * 7));
            break;
        case 'days':
            date.setDate(date.getDate() - amount);
            break;
        default:
            break;
    }
    return date;
};

const filterDataByTimePeriod = (data, timePeriod) => {
    let thresholdDate;
    switch (timePeriod) {
        case '2 years':
            thresholdDate = subtractDate('years', 2);
            break;
        case '1 year':
            thresholdDate = subtractDate('years', 1);
            break;
        case '6 months':
            thresholdDate = subtractDate('months', 6);
            break;
        case '3 months':
            thresholdDate = subtractDate('months', 3);
            break;
        case '1 month':
            thresholdDate = subtractDate('months', 1);
            break;
        case '1 week':
            thresholdDate = subtractDate('weeks', 1);
            break;
        case '3 days':
            thresholdDate = subtractDate('days', 3);
            break;
        case '1 day':
            thresholdDate = subtractDate('days', 1);
            break;
        default:
            return data; // Lifetime or default case
    }
    return data.filter((x) => new Date(x._id) >= thresholdDate);
};

const getStartDateFromPeriod = (period) => {
    const currentDate = new Date();
    switch (period) {
        case "Lifetime":
            return new Date(0); // Return epoch date for lifetime
        case "2 years":
            currentDate.setFullYear(currentDate.getFullYear() - 2);
            break;
        case "1 year":
            currentDate.setFullYear(currentDate.getFullYear() - 1);
            break;
        case "6 months":
            currentDate.setMonth(currentDate.getMonth() - 6);
            break;
        case "3 months":
            currentDate.setMonth(currentDate.getMonth() - 3);
            break;
        case "1 month":
            currentDate.setMonth(currentDate.getMonth() - 1);
            break;
        case "1 week":
            currentDate.setDate(currentDate.getDate() - 7);
            break;
        case "3 days":
            currentDate.setDate(currentDate.getDate() - 3);
            break;
        case "1 day":
            currentDate.setDate(currentDate.getDate() - 1);
            break;
        default:
            return currentDate; // Return current date as default
    }
    return currentDate.toISOString().split('T')[0]; // Return date in 'YYYY-MM-DD' format
};

const Home = () => {
    const navigate = useNavigate();
    const filter = createFilterOptions();
    const [value, setValue] = useState(0);
    const [value2, setValue2] = useState(0);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user") || "false") || false);
    if (!user) navigate("/login");

    const [expandedRow, setExpandedRow] = useState(null);

    const handleExpandClick = (index) => {
        if (expandedRow === index) {
            setExpandedRow(null); // Collapse if the same row is clicked
        } else {
            setExpandedRow(index); // Expand the clicked row
        }
    };

    const [game, setGame] = useState({});
    const [playGraphTimePeriod, setPlayGraphTimePeriod] = useState('Lifetime');
    const [playGraphData, setPlayGraphData] = useState([]);
    const [crashDailyGraph, setCrashDailyGraph] = useState([]);
    const [installDailyGraph, setInstallDailyGraph] = useState([]);
    const [uninstallDailyGraph, setUninstallDailyGraph] = useState([]);
    const [updateDailyGraph, setUpdateDailyGraph] = useState([]);
    const [sumOfEventsData, setSumOfEventsData] = useState([]);

    const [gamePageVisitData, setGamePageVisitData] = useState([]);
    const [gamePageBounceData, setGamePageBounceData] = useState([]);


    const [customEvents, setCustomEvents] = useState([]);
    const [selectedCustomEvent, setSelectedCustomEvent] = useState("Create Custom Event");
    const [selectedCustomEventData, setSelectedCustomEventData] = useState({});
    const [selectedDateCustomEventData, setSelectedDateCustomEventData] = useState({});

    const [addPropertyModalVisible, setAddPropertyModalVisible] = useState(false);
    const [properties, setProperties] = useState([]);
    const [propertyType, setPropertyType] = useState('String');
    const [propertyEnums, setPropertyEnums] = useState([]);
    const [propertyName, setPropertyName] = useState('');
    const [minimumLength, setMinimumLength] = useState("");
    const [maximumLength, setMaximumLength] = useState("");
    const [isRequiredProperty, setIsRequiredProperty] = useState(false);
    const [isEnumsUsedProperty, setIsEnumsUsedProperty] = useState(false);
    const [eventName, setEventName] = useState('');

    useEffect(() => {
        util.postRequest("/api/analytics/getCustomEventStructures").then((res) => {
            console.log(res);
            setCustomEvents(res);
        })

        util.postRequest("/api/analytics/getGameAnalytics", {
            appID: window.location.hash.split("/")[2]
        }).then((res) => {
            console.log(res);

            // Normalize data function
            const normalizeData = (data, dates, type) => {
                const dataMap = new Map(data.map(item => [item._id, item.count]));
                return dates.map(date => ({
                    _id: date,
                    count: dataMap.has(date) ? dataMap.get(date) : 0,
                    type: type // Add an extra property to indicate the type (install or uninstall)
                }));
            };

            // Combine and sort unique dates from both install and uninstall data
            const combinedDates = [...new Set([...res.installDailyGraph, ...res.uninstallDailyGraph, ...res.updateDailyGraph, ...res.gameDailyPlaysGraph, ...res.crashDailyGraph, ...res.gamePageBounceDailyGraph, ...res.gamePageVisitDailyGraph].map(x => x._id))].sort();

            // Normalize both install and uninstall data with type indication
            const normalizedInstallData = normalizeData(res.installDailyGraph, combinedDates, 'install');
            const normalizedUninstallData = normalizeData(res.uninstallDailyGraph, combinedDates, 'uninstall');
            const normalizedUpdateData = normalizeData(res.updateDailyGraph, combinedDates, 'update');
            const normalizedPlayGraphData = normalizeData(res.gameDailyPlaysGraph, combinedDates, 'play');
            const normalizedCrashData = normalizeData(res.crashDailyGraph, combinedDates, 'crash');
            const normalizedGamePageVisitData = normalizeData(res.gamePageVisitDailyGraph, combinedDates, 'visit');
            const normalizedGamePageBounceData = normalizeData(res.gamePageBounceDailyGraph, combinedDates, 'bounce');

            res.gameDailyPlaysGraph = normalizedPlayGraphData;
            res.crashDailyGraph = normalizedCrashData;
            res.installDailyGraph = normalizedInstallData;
            res.uninstallDailyGraph = normalizedUninstallData;
            res.updateDailyGraph = normalizedUpdateData;
            res.gamePageVisitDailyGraph = normalizedGamePageVisitData;
            res.gamePageBounceDailyGraph = normalizedGamePageBounceData;

            // Combine normalized data to calculate net installs/uninstalls per day
            const combinedData = [...normalizedInstallData, ...normalizedUninstallData].reduce((acc, curr) => {
                const date = curr._id;
                const count = curr.count;
                const type = curr.type;

                if (!acc[date]) acc[date] = { _id: date, netCount: 0 };
                acc[date].netCount += type === 'install' ? count : -count; // Use the type to correctly adjust the net count

                return acc;
            }, {});

            // Prepare the data for the chart
            const sumOfEventsData = Object.values(combinedData);

            // Update the res object with the sum of events data
            res.sumInstallDailyGraph = sumOfEventsData;
            console.log(res)

            // Assuming setGame and other set functions are defined elsewhere and work as intended
            setGame(res);
            // Assuming filterDataByTimePeriod is defined elsewhere and works as intended
            setPlayGraphData(filterDataByTimePeriod(res.gameDailyPlaysGraph, playGraphTimePeriod));
            setCrashDailyGraph(filterDataByTimePeriod(res.crashDailyGraph, playGraphTimePeriod));
            setInstallDailyGraph(filterDataByTimePeriod(res.installDailyGraph, playGraphTimePeriod));
            setUninstallDailyGraph(filterDataByTimePeriod(res.uninstallDailyGraph, playGraphTimePeriod));
            setUpdateDailyGraph(filterDataByTimePeriod(res.updateDailyGraph, playGraphTimePeriod));
            setSumOfEventsData(filterDataByTimePeriod(res.sumInstallDailyGraph, playGraphTimePeriod));
            setGamePageVisitData(filterDataByTimePeriod(res.gamePageVisitDailyGraph, playGraphTimePeriod));
            setGamePageBounceData(filterDataByTimePeriod(res.gamePageBounceDailyGraph, playGraphTimePeriod));
        })
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function formatSessionLength(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds - (hours * 3600)) / 60);
        const remainingSeconds = seconds - (hours * 3600) - (minutes * 60);

        let result = "";
        if (hours > 0) {
            result += `${hours}h `;
        }
        if (minutes > 0 || hours > 0) { // Include minutes if there are hours
            result += `${minutes}m `;
        }
        result += `${remainingSeconds.toFixed(0)}s`;

        return result.trim();
    }

    return (
        <Box style={{ marginTop: 40, paddingBottom: 80, margin: 0, maxWidth: "xs" }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Analytics" />
                    <Tab label="Custom Events" />
                </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
                <Typography
                    style={{
                        color: "#fff",
                        fontSize: 22,
                        fontFamily: "Russo One",
                        zIndex: 1,
                    }}
                >
                    Game Analytics
                </Typography>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableBody>
                            <TableRow>
                                <TableCell>Game Name</TableCell>
                                <TableCell>{game.gameName}</TableCell>
                                <TableCell>Average Session Length</TableCell>
                                <TableCell>{`${game.averageSessionLength > 0 ? "" : "-"}` + formatSessionLength(game.averageSessionLength / 1000)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Game Developer</TableCell>
                                <TableCell>{game.gameDeveloper}</TableCell>
                                <TableCell>Average Daily Plays</TableCell>
                                <TableCell>{game.dailyAveragePlays}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Current Players</TableCell>
                                <TableCell>{game.currentPlayers}</TableCell>
                                <TableCell>Lifetime Unique Players</TableCell>
                                <TableCell>{game.uniquePlayers}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Follows</TableCell>
                                <TableCell>{game.follows}</TableCell>
                                <TableCell>Game Creation</TableCell>
                                <TableCell>{new Date(game.created).toLocaleString()}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Select
                    value={playGraphTimePeriod}
                    onChange={(e) => {
                        setPlayGraphTimePeriod(e.target.value);
                        setPlayGraphData(filterDataByTimePeriod(game.gameDailyPlaysGraph, e.target.value));
                        setCrashDailyGraph(filterDataByTimePeriod(game.crashDailyGraph, e.target.value));
                        setInstallDailyGraph(filterDataByTimePeriod(game.installDailyGraph, e.target.value));
                        setUninstallDailyGraph(filterDataByTimePeriod(game.uninstallDailyGraph, e.target.value));
                        setUpdateDailyGraph(filterDataByTimePeriod(game.updateDailyGraph, e.target.value));
                        setSumOfEventsData(filterDataByTimePeriod(game.sumInstallDailyGraph, e.target.value));
                        setGamePageVisitData(filterDataByTimePeriod(game.gamePageVisitDailyGraph, e.target.value));
                        setGamePageBounceData(filterDataByTimePeriod(game.gamePageBounceDailyGraph, e.target.value));
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    sx={{
                        width: "100%",
                        marginTop: 2,
                        marginBottom: 2,
                        fontFamily: "Russo One",
                    }}
                >
                    <MenuItem value="Lifetime">Lifetime</MenuItem>
                    <MenuItem value="2 years">2 years</MenuItem>
                    <MenuItem value="1 year">1 year</MenuItem>
                    <MenuItem value="6 months">6 months</MenuItem>
                    <MenuItem value="3 months">3 months</MenuItem>
                    <MenuItem value="1 month">1 month</MenuItem>
                    <MenuItem value="1 week">1 week</MenuItem>
                    <MenuItem value="3 days">3 days</MenuItem>
                    <MenuItem value="1 day">1 day</MenuItem>
                </Select>

                {game?.gameDailyPlaysGraph && <LineChart
                    xAxis={[{
                        data: [...new Set([...installDailyGraph, ...uninstallDailyGraph, ...updateDailyGraph, ...playGraphData, ...crashDailyGraph, ...gamePageBounceData, ...gamePageVisitData].map(x => x._id))].sort(),
                        scaleType: 'point'
                    }]}
                    series={[
                        {
                            data: playGraphData.map((x) => x.count),
                            label: "   Daily Play Events",
                            color: "#28c916"
                        },
                        {
                            data: crashDailyGraph.map((x) => x.count),
                            label: "   Daily Crash Events",
                            color: "#e60b0b"
                        }
                    ]}
                    height={400}
                    title='Daily Plays'
                    sx={{
                        fontFamily: "Russo One",
                    }}
                    grid={{
                        vertical: true,
                        horizontal: true
                    }}
                >
                    <LinePlot />
                    <MarkPlot />
                    <ChartsXAxis />
                    <ChartsYAxis />
                    {game.gameVersionDates.map((x) => {
                        return <ChartsReferenceLine key={x.date.split("T")[0]} x={x.date.split("T")[0]}
                            label={"Release: " + x.label}
                            spacing={15}
                            lineStyle={{
                                strokeDasharray: "10 10",
                            }}
                            labelStyle={{
                                fill: "#dedede",
                                fontFamily: "Russo One",
                                fontWeight: "bold",
                            }}
                        />
                    })}
                </LineChart>}

                {game?.installDailyGraph && game?.uninstallDailyGraph && <LineChart
                    xAxis={[{
                        data: [...new Set([...installDailyGraph, ...uninstallDailyGraph, ...updateDailyGraph, ...playGraphData, ...crashDailyGraph, ...gamePageBounceData, ...gamePageVisitData].map(x => x._id))].sort(),
                        scaleType: 'point'
                    }]}
                    series={[
                        {
                            data: installDailyGraph.map((x) => x.count),
                            label: "   Daily Install Events",
                            color: "#28c916"
                        },
                        {
                            data: uninstallDailyGraph.map((x) => x.count),
                            label: "   Daily Uninstall Events",
                            color: "#c20808"
                        },
                        {
                            data: updateDailyGraph.map((x) => x.count),
                            label: "   Daily Update Events",
                            color: "#e6920b"
                        },
                        {
                            data: sumOfEventsData.map((x) => x.netCount),
                            label: "   Net Change",
                            color: "#0bdee6"
                        },
                    ]}
                    height={400}
                    title='Daily Installs, Uninstalls and Updates'
                    sx={{
                        fontFamily: "Russo One",
                    }}
                    grid={{
                        vertical: true,
                        horizontal: true
                    }}
                >
                    <LinePlot />
                    <MarkPlot />
                    <ChartsXAxis />
                    <ChartsYAxis />
                    {game.gameVersionDates.map((x) => {
                        return <ChartsReferenceLine key={x.date.split("T")[0]} x={x.date.split("T")[0]}
                            label={"Release: " + x.label}
                            spacing={15}
                            lineStyle={{
                                strokeDasharray: "10 10",
                            }}
                            labelStyle={{
                                fill: "#dedede",
                                fontFamily: "Russo One",
                                fontWeight: "bold",
                            }}
                        />
                    })}
                </LineChart>}

                {game?.installDailyGraph && game?.uninstallDailyGraph && <LineChart
                    xAxis={[{
                        data: [...new Set([...installDailyGraph, ...uninstallDailyGraph, ...updateDailyGraph, ...playGraphData, ...crashDailyGraph, ...gamePageBounceData, ...gamePageVisitData].map(x => x._id))].sort(),
                        scaleType: 'point'
                    }]}
                    series={[
                        {
                            data: gamePageVisitData.map((x) => x.count),
                            label: "    Daily Game Page Visit Events",
                            color: "#28c916"
                        },
                        {
                            data: gamePageBounceData.map((x) => x.count),
                            label: "    Daily Game Page Bounce Events",
                            color: "#c20808"
                        },
                    ]}
                    height={400}
                    title='Daily Installs, Uninstalls and Updates'
                    sx={{
                        fontFamily: "Russo One",
                    }}
                    grid={{
                        vertical: true,
                        horizontal: true
                    }}
                >
                    <LinePlot />
                    <MarkPlot />
                    <ChartsXAxis />
                    <ChartsYAxis />
                    {game.gameVersionDates.map((x) => {
                        return <ChartsReferenceLine key={x.date.split("T")[0]} x={x.date.split("T")[0]}
                            label={"Release: " + x.label}
                            spacing={15}
                            lineStyle={{
                                strokeDasharray: "10 10",
                            }}
                            labelStyle={{
                                fill: "#dedede",
                                fontFamily: "Russo One",
                                fontWeight: "bold",
                            }}
                        />
                    })}
                </LineChart>}

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDropDown />}
                    >
                        <Typography
                            style={{
                                color: "#fff",
                                fontFamily: "Russo One",
                                zIndex: 1,
                            }}
                        >
                            Crash Reports
                        </Typography>
                    </AccordionSummary>

                    <TableContainer component={Paper}>
                        {game.crashLogs && (
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Crash ID</TableCell>
                                        <TableCell>Crash Date</TableCell>
                                        <TableCell>Crash Platform</TableCell>
                                        <TableCell>Crash App Version</TableCell>
                                        <TableCell>Exit Code</TableCell>
                                        <TableCell>Expand</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {game.crashLogs.length > 0 ? game.crashLogs.map((row, i) => (
                                        <Fragment key={i}>
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row">{i}</TableCell>
                                                <TableCell>{new Date(row.logData.date).toLocaleString()}</TableCell>
                                                <TableCell>{row.client.platform}</TableCell>
                                                <TableCell>{`versionID: ${row.logData.version}`}</TableCell>
                                                <TableCell>{row.logData.exitCode}</TableCell>
                                                <TableCell>
                                                    <IconButton onClick={() => handleExpandClick(i)}>
                                                        {expandedRow === i ? <ArrowDropUp /> : <ArrowDropDown />}
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                                                    <Collapse in={expandedRow === i} timeout="auto" unmountOnExit>
                                                        <Box margin={1}>
                                                            <Typography variant="h6" gutterBottom component="div">
                                                                Crash Log - StdErr
                                                            </Typography>
                                                            <pre style={{
                                                                whiteSpace: "pre-wrap",
                                                                fontFamily: "monospace",
                                                            }}>{row.logData.stderr}</pre>

                                                            <Typography variant="h6" gutterBottom component="div">
                                                                Crash Log - StdOut
                                                            </Typography>

                                                            <pre style={{
                                                                whiteSpace: "pre-wrap",
                                                                fontFamily: "monospace",
                                                            }}>{row.logData.stdout}</pre>
                                                        </Box>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </Fragment>
                                    ))
                                        :
                                        <TableRow>
                                            <TableCell style={{ fontFamily: "monospace" }} colSpan={6}>No crash reports available... Yet..!</TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        )}
                    </TableContainer>
                </Accordion>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginLeft: -3, marginTop: -3, marginBottom: 3 }}>
                    <Tabs value={value2} onChange={(e, v) => {
                        setValue2(v)
                        if (v === 1) {
                            setSelectedCustomEvent("Select")
                            setPlayGraphTimePeriod("1 month")
                        } else {
                            setSelectedCustomEvent("Create Custom Event")
                        }
                    }}>
                        <Tab label="Custom Events" />
                        <Tab label="Custom Event Graphs" />
                    </Tabs>
                </Box>

                <CustomTabPanel value={value2} index={0}>
                    <Typography
                        style={{
                            color: "#fff",
                            fontSize: 22,
                            fontFamily: "Russo One",
                            zIndex: 1,
                        }}
                    >
                        Custom Events
                    </Typography>

                    <Typography
                        style={{
                            color: "#fff",
                            fontSize: 14,
                            fontFamily: "Russo One",
                            marginTop: 20,
                        }}
                    >
                        Create custom events to track specific actions in your game. You can use these events to track player progress, achievements, and more.
                        <br />
                        You might want to check out the <a href="https://docs.vorecade.com/docs/analytics/custom-events" target="_blank" rel="noreferrer">documentation</a> for more information.
                        <br />
                        <strong>YOU CAN'T CHANGE ADDED PROPERTIES. EVENTS CAN'T BE DELETED.</strong>
                    </Typography>

                    <Select
                        value={selectedCustomEvent}
                        sx={{
                            mt: 5
                        }}
                        onChange={(e) => {
                            if (e.target.value === "Create Custom Event") {
                                setSelectedCustomEvent("Create Custom Event")
                                setEventName("")
                                setProperties([])
                            } else {
                                setSelectedCustomEvent(e.target.value)
                                setEventName(e.target.value)
                                console.log(customEvents.find(x => x.eventName === e.target.value).data)
                                setProperties(customEvents.find(x => x.eventName === e.target.value).data)
                            }
                        }}
                    >
                        {customEvents.map((event, i) => {
                            return (
                                <MenuItem value={event.eventName}>{event.eventName}</MenuItem>
                            )
                        })}
                        <MenuItem selected={selectedCustomEvent === "Create Custom Event"} value={"Create Custom Event"}>Create Custom Event</MenuItem>
                    </Select>

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        <TextField
                            disabled={selectedCustomEvent !== "Create Custom Event"}
                            value={eventName}
                            onChange={(e) => setEventName(e.target.value)}
                            label="Event Name"
                            sx={{
                                mt: 5
                            }}
                        />

                        <Button
                            disabled={eventName.length === 0}
                            onClick={() => setAddPropertyModalVisible(true)}
                            sx={{
                                mt: 5
                            }}
                            variant="contained"
                        >
                            Add Property
                        </Button>

                        <Dialog
                            open={addPropertyModalVisible}
                            onClose={() => setAddPropertyModalVisible(false)}
                        >
                            <DialogContent>
                                <DialogContentText>
                                    Fill out the details of your event property.
                                </DialogContentText>

                                <Box display="flex">
                                    <TextField
                                        label="Property Name"
                                        value={propertyName}
                                        onChange={(e) => {
                                            // check if property name starts with a non letter

                                            e.target.value = e.target.value.replace(/ /g, "_")

                                            if (e.target.value.length > 0 && !/^[a-zA-Z]/.test(e.target.value)) {
                                                e.target.value = propertyName
                                            }

                                            setPropertyName(e.target.value)
                                        }}
                                        sx={{
                                            mt: 5,
                                        }}
                                        InputProps={{
                                            style: {
                                                borderTopRightRadius: 0,
                                                borderBottomRightRadius: 0,
                                            }
                                        }}
                                        fullWidth
                                    />

                                    <Select
                                        disabled={propertyName.length === 0}
                                        sx={{
                                            mt: 5,
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0,
                                        }}
                                        value={propertyType}

                                        onChange={(e) => setPropertyType(e.target.value)}
                                    >
                                        <MenuItem value="String">String</MenuItem>
                                        <MenuItem value="Number">Number</MenuItem>
                                        <MenuItem value="Boolean">Boolean</MenuItem>
                                    </Select>
                                </Box>

                                <Box display="flex">
                                    <TextField
                                        disabled={propertyName.length === 0 || propertyType === "Boolean"}
                                        label="Minimum (non inclusive)"
                                        onChange={(e) => setMinimumLength(e.target.value)}
                                        type={"number"}
                                        value={minimumLength}
                                        sx={{
                                            mt: 5
                                        }}
                                        InputProps={{
                                            style: {
                                                borderTopRightRadius: 0,
                                                borderBottomRightRadius: 0,
                                            }
                                        }}
                                        fullWidth
                                    />
                                    <TextField
                                        disabled={propertyName.length === 0 || propertyType === "Boolean"}
                                        label="Maximum (non inclusive)"
                                        onChange={(e) => setMaximumLength(e.target.value)}
                                        type={"number"}
                                        value={maximumLength}
                                        sx={{
                                            mt: 5
                                        }}
                                        InputProps={{
                                            style: {
                                                borderTopLeftRadius: 0,
                                                borderBottomLeftRadius: 0,
                                            }
                                        }}
                                        fullWidth
                                    />
                                </Box>

                                <FormGroup
                                    sx={{
                                        mt: 2,
                                        mb: 2,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Tooltip title="If checked, this property will be required for the event to be logged!">
                                        <FormControlLabel control={<Checkbox
                                            value={isRequiredProperty}
                                            onChange={(v) => {
                                                setIsRequiredProperty(v.target.checked)
                                            }}
                                            disabled={propertyName.length === 0} />} label="Required" />
                                    </Tooltip>

                                    <Tooltip title="If checked, this property will only accept values in the enums!">
                                        <FormControlLabel control={<Checkbox
                                            value={isEnumsUsedProperty}
                                            onChange={(v) => {
                                                setIsEnumsUsedProperty(v.target.checked)
                                            }} disabled={propertyName.length === 0 || propertyType === "Boolean"} />} label="Use Enums" />
                                    </Tooltip>
                                </FormGroup>

                                <Autocomplete
                                    disabled={propertyName.length === 0 || propertyType !== 'String' || !isEnumsUsedProperty}
                                    multiple
                                    id="enums"
                                    options={propertyEnums}
                                    freeSolo
                                    onChange={(e, v) => {
                                        setPropertyEnums(v.map(x => x.inputValue))
                                    }}
                                    sx={{
                                        width: 450
                                    }}
                                    getOptionLabel={(option) => option.inputValue}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        const { inputValue } = params;
                                        // Suggest the creation of a new value
                                        const isExisting = options.some((option) => inputValue === option);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                inputValue,
                                            });
                                        }

                                        console.log(filtered)

                                        return filtered;
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            autoFocus
                                            label="Enums (Optional)"
                                            type="text"
                                            style={{
                                            }}
                                            fullWidth
                                            onChange={(e) => {

                                            }}
                                        />
                                    )}
                                />
                            </DialogContent>

                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        let nProp = {
                                            kind: propertyType,
                                            name: propertyName,
                                            required: isRequiredProperty,
                                            enums: isEnumsUsedProperty ? propertyEnums : [],
                                            minLength: parseInt(minimumLength),
                                            maxLength: parseInt(maximumLength)
                                        }

                                        setProperties({ ...properties, [propertyName]: nProp });

                                        setAddPropertyModalVisible(false)
                                        setPropertyType('String')
                                        setPropertyEnums([])
                                        setPropertyName('')
                                        setMinimumLength('')
                                        setMaximumLength('')
                                        setIsRequiredProperty(false)
                                        setIsEnumsUsedProperty(false)
                                    }}
                                >
                                    Add Property
                                </Button>

                                <Button
                                    onClick={() => {
                                        setAddPropertyModalVisible(false)
                                        setPropertyType('String')
                                        setPropertyEnums([])
                                        setPropertyName('')
                                        setMinimumLength('')
                                        setMaximumLength('')
                                        setIsRequiredProperty(false)
                                        setIsEnumsUsedProperty(false)
                                    }}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>

                        </Dialog>

                        <Box>
                            <Typography
                                style={{
                                    color: "#fff",
                                    fontSize: 14,
                                    fontFamily: "Russo One",
                                    marginTop: 20,
                                }}
                            >
                                Properties
                            </Typography>

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                                }}
                            >
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>ID</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Kind</TableCell>
                                                <TableCell>Required</TableCell>
                                                <TableCell>Minimum</TableCell>
                                                <TableCell>Maximum</TableCell>
                                                <TableCell>Enums</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.entries(properties).length !== 0 ?
                                                Object.entries(properties).map(([entryName, row], i) => (
                                                    <Fragment key={i}>
                                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell component="th" scope="row">{i}</TableCell>
                                                            <TableCell>{entryName}</TableCell> {/* Updated to use entryName */}
                                                            <TableCell>{row.kind}</TableCell>
                                                            <TableCell>{row.required ? <Check /> : <Close />}</TableCell>
                                                            <TableCell>{row.kind === "Boolean" ? "-" : row.minLength || "-"}</TableCell>
                                                            <TableCell>{row.kind === "Boolean" ? "-" : row.maxLength || "-"}</TableCell>
                                                            <TableCell>{row.enums && row.enums.length > 0 ? row.enums.join(", ") : "-"}</TableCell>
                                                        </TableRow>
                                                    </Fragment>
                                                ))
                                                :
                                                <TableCell style={{ fontFamily: "monospace" }} colSpan={6}>No properties... Go add some!</TableCell>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>

                            <Button
                                sx={{
                                    mt: 5
                                }}
                                variant="contained"
                                disabled={properties.length === 0 || eventName.length === 0}
                                onClick={() => {
                                    loadingCaller(true, "Saving Event...")

                                    util.postRequest("/api/analytics/createCustomEventStructure", {
                                        eventName: eventName,
                                        data: properties
                                    }).then(async (res) => {
                                        loadingCaller(false)
                                        console.log(res)

                                        if (res.s) {
                                            util.postRequest("/api/analytics/getCustomEventStructures").then((res) => {
                                                console.log(res);
                                                setCustomEvents(res);
                                                setSelectedCustomEvent("Create Custom Event")
                                                setEventName("")
                                                setProperties([])
                                            })
                                        }
                                    })
                                }}
                            >
                                Save Event
                            </Button>
                        </Box>

                    </Box>

                </CustomTabPanel>

                <CustomTabPanel value={value2} index={1}>
                    <Typography
                        style={{
                            color: "#fff",
                            fontSize: 22,
                            fontFamily: "Russo One",
                            zIndex: 1,
                        }}
                    >
                        Custom Event Graphs
                    </Typography>

                    <Typography
                        style={{
                            color: "#fff",
                            fontSize: 14,
                            fontFamily: "Russo One",
                            marginTop: 20,
                        }}
                    >
                        Things will break! This feature is still in development!!
                        <br />
                        Every event will be shown in the master chart. Individual event details can be viewed by clicking on the event in the master chart.
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            mt: 5
                        }}
                    >
                        <Select
                            value={selectedCustomEvent}
                            fullWidth
                            onChange={(e) => {
                                setSelectedCustomEvent(e.target.value)

                                const startDate = getStartDateFromPeriod(playGraphTimePeriod); // Use the selected time period to calculate start date

                                util.postRequest("/api/analytics/getCustomEventAnalytics", {
                                    eventName: e.target.value,
                                    startDate: startDate,
                                    endDate: new Date()
                                }).then((res) => {
                                    const generateDateRange = (startDate, endDate) => {
                                        const dates = [];
                                        let currentDate = new Date(startDate);
                                        while (currentDate <= endDate) {
                                            dates.push(new Date(currentDate));
                                            currentDate.setDate(currentDate.getDate() + 1);
                                        }
                                        return dates;
                                    };

                                    const allDatesInRange = generateDateRange(res.dailyCounts[0]?._id, new Date());

                                    // 3. Fill missing dates
                                    const filledDailyCounts = allDatesInRange.map(date => {
                                        const dateString = date.toISOString().split('T')[0];
                                        const existingEntry = res.dailyCounts.find(entry => entry?._id === dateString);
                                        return existingEntry || { _id: dateString, count: 0 };
                                    });

                                    res.dailyCounts = filledDailyCounts;

                                    setSelectedCustomEventData(res);
                                    console.log(res)
                                })
                            }}

                            sx={{
                                fontFamily: "Russo One",
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                            }}
                        >
                            {customEvents.map((event, i) => {
                                return (
                                    <MenuItem value={event.eventName}>{event.eventName}</MenuItem>
                                )
                            })}
                            <MenuItem selected={selectedCustomEvent === "Select"} value={"Select"}>Select</MenuItem>
                        </Select>

                        <Select
                            value={playGraphTimePeriod}
                            disabled={selectedCustomEvent === "Select"}
                            fullWidth
                            onChange={(e) => {
                                setPlayGraphTimePeriod(e.target.value);
                                setPlayGraphData(filterDataByTimePeriod(game.gameDailyPlaysGraph, e.target.value));
                                setCrashDailyGraph(filterDataByTimePeriod(game.crashDailyGraph, e.target.value));
                                setInstallDailyGraph(filterDataByTimePeriod(game.installDailyGraph, e.target.value));
                                setUninstallDailyGraph(filterDataByTimePeriod(game.uninstallDailyGraph, e.target.value));
                                setUpdateDailyGraph(filterDataByTimePeriod(game.updateDailyGraph, e.target.value));
                                setSumOfEventsData(filterDataByTimePeriod(game.sumInstallDailyGraph, e.target.value));
                                setGamePageVisitData(filterDataByTimePeriod(game.gamePageVisitDailyGraph, e.target.value));
                                setGamePageBounceData(filterDataByTimePeriod(game.gamePageBounceDailyGraph, e.target.value));

                                const startDate = getStartDateFromPeriod(e.target.value); // Use the selected time period to calculate start date

                                util.postRequest("/api/analytics/getCustomEventAnalytics", {
                                    eventName: selectedCustomEvent,
                                    startDate: startDate,
                                    endDate: new Date()
                                }).then((res) => {
                                    const generateDateRange = (startDate, endDate) => {
                                        const dates = [];
                                        let currentDate = new Date(startDate);
                                        while (currentDate <= endDate) {
                                            dates.push(new Date(currentDate));
                                            currentDate.setDate(currentDate.getDate() + 1);
                                        }
                                        return dates;
                                    };

                                    const allDatesInRange = generateDateRange(res.dailyCounts[0]?._id, new Date());

                                    // 3. Fill missing dates
                                    const filledDailyCounts = allDatesInRange.map(date => {
                                        const dateString = date.toISOString().split('T')[0];
                                        const existingEntry = res.dailyCounts.find(entry => entry?._id === dateString);
                                        return existingEntry || { _id: dateString, count: 0 };
                                    });

                                    res.dailyCounts = filledDailyCounts;

                                    setSelectedCustomEventData(res);
                                    console.log(res)
                                })

                            }}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            sx={{
                                fontFamily: "Russo One",
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                            }}
                        >
                            <MenuItem value="Lifetime">Lifetime</MenuItem>
                            <MenuItem value="2 years">2 years</MenuItem>
                            <MenuItem value="1 year">1 year</MenuItem>
                            <MenuItem value="6 months">6 months</MenuItem>
                            <MenuItem value="3 months">3 months</MenuItem>
                            <MenuItem value="1 month">1 month</MenuItem>
                            <MenuItem value="1 week">1 week</MenuItem>
                            <MenuItem value="3 days">3 days</MenuItem>
                            <MenuItem value="1 day">1 day</MenuItem>
                        </Select>
                    </Box>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableBody>
                                <TableRow>
                                    <TableCell>Event Name</TableCell>
                                    <TableCell>{selectedCustomEvent}</TableCell>
                                    <TableCell>Event Count</TableCell>
                                    <TableCell>{selectedCustomEventData?.totalDocuments}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Daily Average</TableCell>
                                    <TableCell>{selectedCustomEventData?.dailyAverage?.toFixed(2)}</TableCell>
                                    <TableCell>Created</TableCell>
                                    <TableCell>{new Date(selectedCustomEventData?.eventSchemaCreatedDate).toLocaleString()}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {selectedCustomEventData?.dailyCounts && <LineChart
                        xAxis={[{
                            data: [...new Set([...selectedCustomEventData?.dailyCounts].map(x => x._id))].sort(),
                            scaleType: 'point'
                        }]}
                        series={[
                            {
                                data: selectedCustomEventData?.dailyCounts.map((x) => x.count),
                                label: "   Daily Event Counts",
                                color: "#28c916"
                            },
                        ]}
                        height={400}
                        sx={{
                            fontFamily: "Russo One",
                        }}
                        grid={{
                            vertical: true,
                            horizontal: true
                        }}
                        onAxisClick={(e, d) => {
                            util.postRequest("/api/analytics/getAllCustomEventsOnDate", {
                                date: d.axisValue,
                                eventName: selectedCustomEvent
                            }).then((res) => {
                                console.log(res)
                                setSelectedDateCustomEventData(res)
                            })
                        }}
                    >
                        <LinePlot />
                        <MarkPlot />
                        <ChartsXAxis />
                        <ChartsYAxis />
                        {game.gameVersionDates.map((x) => {
                            return <ChartsReferenceLine key={x.date.split("T")[0]} x={x.date.split("T")[0]}
                                label={"Release: " + x.label}
                                spacing={15}
                                lineStyle={{
                                    strokeDasharray: "10 10",
                                }}
                                labelStyle={{
                                    fill: "#dedede",
                                    fontFamily: "Russo One",
                                    fontWeight: "bold",
                                }}
                            />
                        })}
                    </LineChart>}
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Property Name</TableCell>
                                    <TableCell>Value</TableCell>
                                    <TableCell>Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedDateCustomEventData?.customEventsData?.map((event, eventIndex) => (
                                    <Fragment key={eventIndex}>
                                        <TableRow>
                                            <TableCell component="th" scope="row" colSpan={4}>
                                                {eventIndex}
                                            </TableCell>
                                        </TableRow>
                                        {Object.entries(event.data).map(([key, value], propIndex) => (
                                            <TableRow key={propIndex}>
                                                <TableCell></TableCell> {/* Empty cell for alignment */}
                                                <TableCell>{key}</TableCell>
                                                <TableCell>{value.toString()}</TableCell>
                                                <TableCell>{new Date(event.created).toLocaleString()}</TableCell>
                                            </TableRow>
                                        ))}
                                    </Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </CustomTabPanel>
            </CustomTabPanel>


        </Box >
    );
};

export default Home;