import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, InputLabel, LinearProgress, Menu, MenuItem, Select, Typography } from '@mui/material';
import util from '../util';
import Markdown from '../components/Markdown';
import { Delete, Download, Info, PlayArrow, Settings, Web } from '@mui/icons-material';
import zIndex from '@mui/material/styles/zIndex';
import { callLoadingLine, loadingCaller, snackCaller } from '../App';
import Image from '../components/Image';

const Home = () => {
    const navigate = useNavigate();
    const [games, setGames] = useState([])
    const [playTimes, setPlayTimes] = useState([])
    const [selectedGame, setSelectedGame] = useState(0)
    const [isGameInstalled, setIsGameInstalled] = useState(false);
    const [isGameUpdate, setIsGameUpdate] = useState(false);
    const [requiredUpdate, setRequiredUpdate] = useState(false);
    const [infoModalVisible, setInfoModalVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [settingsModalVisible, setSettingsModalVisible] = useState(false);
    const [contextMenu, setContextMenu] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [removeFromLibraryModalVisible, setRemoveFromLibraryModalVisible] = useState(false);
    const [selectedVersion, setSelectedVersion] = useState('');

    // shoo the user if they are not on electron

    const handleVersionChange = (event) => {
        setSelectedVersion(event.target.value);
    };

    function refreshGameList(useCache = false) {
        console.log("REFRESHING!!! " + useCache)
        let waitCache = true
        if (useCache) {
            caches.open("request-cache").then((cache) => {
                cache.match("/LOCLIB").then((response) => {
                    if (response) {
                        response.json().then((data) => {
                            setGames(data)
                        })
                    }

                    waitCache = false
                })
            })
        }

        window.ipcRenderer.invoke("getLocalGameList", { force: useCache === false }).then(async (data) => {
            console.log(data)
            while (waitCache && useCache) {
                console.log("WAITING FOR CACHE")
                await new Promise((resolve) => setTimeout(resolve, 100))
            }

            const localGame = data[selectedGame]
            if (localGame) {
                setIsGameInstalled(true)
                const localVersionIndex = localGame.versions.findIndex(version => version?.versionID === localGame?.version?.versionID);
                const newerVersions = localGame.versions.slice(localVersionIndex + 1);
                const newerHardUpdateVersion = newerVersions.find(version => version?.isHardUpdate);

                if (newerHardUpdateVersion) {
                    setIsGameUpdate(true)
                }
            }

            // merge the local lib with the new fetched lib (also remove duplicates)

            let nG = games.map(game => {
                if (data.find(localGame => (localGame.game.appID) === game.game.appID)) return null

                return game
            })

            nG = nG.filter(game => game !== null)

            nG = nG.concat(data)

            setGames(nG)

            util.postRequest("/api/user/getLibrary", {}, () => { }, "application/json", true).then((res) => {
                if (res.status === 200) {
                    let library = res.library.map(game => {
                        return {
                            game: game,
                            versions: game.versions,
                            version: {
                                ...game.versions[game.versions.length - 1],
                                thumbnail: game.versions[game.versions.length - 1].thumbnail.url
                            },
                            folderSize: 0,
                            installedOn: "1970-01-01T00:00:00.000Z"
                        }
                    })

                    library.forEach(libraryGame => {
                        const localGameIndex = data.findIndex(localGame => localGame.game.appID === libraryGame.game.appID);
                        if (localGameIndex !== -1) {
                            // Update the versions of the existing game in data
                            data[localGameIndex].versions = libraryGame.versions;
                        } else {
                            // Add the game to data if it doesn't exist
                            data.push(libraryGame);
                        }
                    });

                    library = library.filter(game => game !== null)

                    setGames(data)
                    setPlayTimes(res.playTimes)

                    caches.open("request-cache").then((cache) => {
                        // store the library in the cache
                        cache.put("/LOCLIB", new Response(JSON.stringify(data.concat(library)), { status: 200 }))
                    })
                } else {
                    setGames(data)

                    caches.open("request-cache").then((cache) => {
                        // store the library in the cache
                        cache.put("/LOCLIB", new Response(JSON.stringify(data), { status: 200 }))
                    })
                }
            }).catch((err) => {
                setGames(data)

                caches.open("request-cache").then((cache) => {
                    // store the library in the cache
                    cache.put("/LOCLIB", new Response(JSON.stringify(data), { status: 200 }))
                })
                console.error(err)
            })
        })
    }

    useEffect(() => {
        if (!window.ipcRenderer) return
        refreshGameList(true)
    }, [])

    if (!window.ipcRenderer) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "calc(100vh - 68.5px)",
                    flexDirection: "column"
                }}
            >
                <Typography
                    variant="h4"
                    gutterBottom
                >
                    I have a feeling you are not using the desktop app.
                </Typography>

                <Typography
                    variant="h4"
                >
                    Library is only available on the desktop app.
                </Typography>

                <Button
                    sx={{
                        mt: 1.5,
                        fontSize: "1.2rem"
                    }}
                    onClick={() => {
                        navigate("/download")
                    }}
                >
                    Download Desktop App
                </Button>
            </div>
        )
    }


    if (games.length === 0) return (<div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 68.5px)",
            flexDirection: "column"
        }}
    >
        <Typography
            variant="h4"
        >
            No games found!
        </Typography>

        <Button
            sx={{
                mt: 1.5,
                fontSize: "1.2rem"
            }}
            onClick={() => {
                navigate("/")
            }}
        >
            Get Games!
        </Button>
    </div>)

    return (
        <div
            style={{
                display: "flex",
                height: "calc(100vh - 93px)",
                overflow: "hidden",
            }}
        >
            <Box
                style={{
                    width: "270px",
                    height: "100%",
                }}
            >
                <Box
                    style={{
                        width: "100%",
                        height: "70px",
                        //background: "linear-gradient(180deg, #121212 0%, #121212 60%, rgba(0,0,0,0) 100%)",
                        padding: 10,
                        paddingBottom: 0
                    }}
                >
                    <Typography
                        variant="h5"
                    >
                        Library
                    </Typography>
                </Box>

                <Box
                    style={{
                        width: "100%",
                        height: "calc(100% - 60px)",
                        marginTop: -10
                    }}
                >
                    <Menu
                        open={contextMenu !== null}
                        onClose={() => {
                            setContextMenu(null);
                            setSelectedGame(0)

                            if (games[0]?.isInstalled) {
                                setIsGameInstalled(true)
                                const localGame = games[0]
                                if (localGame) {
                                    setIsGameInstalled(true)
                                    const localVersionIndex = localGame.versions.findIndex(version => version?.versionID === localGame?.version?.versionID);
                                    const newerVersions = localGame.versions.slice(localVersionIndex + 1);
                                    const newerHardUpdateVersion = newerVersions.find(version => version?.isHardUpdate);

                                    if (newerHardUpdateVersion) {
                                        setIsGameUpdate(true)
                                    } else {
                                        setIsGameUpdate(false)
                                    }
                                }
                            } else {
                                setIsGameInstalled(false)
                                setIsGameUpdate(false)
                            }
                        }}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextMenu !== null
                                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                : undefined
                        }
                    >
                        {games[selectedGame]?.isInstalled && <MenuItem
                            onClick={() => {
                                window.ipcRenderer.invoke("openFolder", { path: games[selectedGame].installPath })
                                setContextMenu(null)
                            }}
                        >
                            Browse Game Files
                        </MenuItem>}

                        <MenuItem
                            onClick={() => {
                                setContextMenu(null)
                                setRemoveFromLibraryModalVisible(true)
                            }}
                        >
                            Remove from Library
                        </MenuItem>
                    </Menu>

                    <Dialog
                        open={removeFromLibraryModalVisible}
                        onClose={() => setRemoveFromLibraryModalVisible(false)}
                    >
                        <DialogTitle>Remove {games[selectedGame]?.game?.name} from Library</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to remove this game from your account library?
                                <br />
                                This action will not delete the game files from your computer.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    setRemoveFromLibraryModalVisible(false)
                                }
                                }>
                                Cancel
                            </Button>
                            <Button
                                onClick={async () => {
                                    util.postRequest("/api/games/removeFromLibrary", {
                                        appID: games[selectedGame]?.game?.appID
                                    })
                                }}
                            >
                                Remove
                            </Button>
                        </DialogActions>
                    </Dialog>


                    <Box
                        style={{
                            textAlign: "left",
                            height: "calc(100% - 20px)",
                            overflowY: "auto",
                        }}
                    >
                        {games.map((game, i) => {
                            if (!game?.version) {
                                console.log(game)
                                return <>
                                    <p>
                                        Game Corrupted. Please reinstall.
                                        {JSON.stringify(game)}
                                    </p>
                                </>
                            }
                            return (
                                <div
                                >
                                    <Button
                                        variant={i === selectedGame ? "outlined" : "text"}
                                        sx={{
                                            textAlign: "left",
                                            justifyContent: "flex-start",
                                            position: "relative",
                                            width: "100%",
                                            transition: "100ms"
                                        }}
                                        onClick={() => {
                                            setSelectedGame(games.indexOf(game))

                                            if (game?.isInstalled) {
                                                setIsGameInstalled(true)
                                                const localGame = games[games.indexOf(game)]
                                                if (localGame) {
                                                    setIsGameInstalled(true)
                                                    const localVersionIndex = localGame.versions.findIndex(version => version?.versionID === localGame?.version?.versionID);
                                                    const newerVersions = localGame.versions.slice(localVersionIndex + 1);
                                                    const newerHardUpdateVersion = newerVersions.find(version => version?.isHardUpdate);

                                                    if (newerHardUpdateVersion) {
                                                        setIsGameUpdate(true)
                                                    } else {
                                                        setIsGameUpdate(false)
                                                    }
                                                }
                                            } else {
                                                setIsGameInstalled(false)
                                                setIsGameUpdate(false)
                                            }
                                        }}

                                        onContextMenu={(event) => {
                                            event.preventDefault();
                                            setContextMenu({
                                                mouseX: event.clientX - 2,
                                                mouseY: event.clientY - 4,
                                            });

                                            setSelectedGame(games.indexOf(game))

                                            if (game?.isInstalled) {
                                                setIsGameInstalled(true)
                                                const localGame = games[games.indexOf(game)]
                                                if (localGame) {
                                                    setIsGameInstalled(true)
                                                    const localVersionIndex = localGame.versions.findIndex(version => version?.versionID === localGame?.version?.versionID);
                                                    const newerVersions = localGame.versions.slice(localVersionIndex + 1);
                                                    const newerHardUpdateVersion = newerVersions.find(version => version?.isHardUpdate);

                                                    if (newerHardUpdateVersion) {
                                                        setIsGameUpdate(true)
                                                    } else {
                                                        setIsGameUpdate(false)
                                                    }
                                                }
                                            } else {
                                                setIsGameInstalled(false)
                                                setIsGameUpdate(false)
                                            }
                                        }}
                                    >
                                        <Image
                                            src={game.version.thumbnail}
                                            style={{
                                                width: 20,
                                                height: 20,
                                                borderRadius: 2,
                                                marginRight: 10,
                                                objectFit: "cover",
                                            }}
                                        />
                                        {game.game.name}
                                    </Button>
                                    {i === games.length - 1 ? <></> : <div
                                        style={{
                                            background: "linear-gradient(180deg, #121212 0%, #121212 60%, rgba(0,0,0,0) 100%)",
                                            width: "100%",
                                            height: 5,
                                        }}
                                    />}
                                </div>
                            )
                        })}
                    </Box>
                </Box>
            </Box>

            <Box
                style={{
                    width: "calc(100% - 270px)",
                    height: "calc(100vh - 94px)",
                    padding: 10,
                    position: "relative",
                    overflowY: "auto"
                }}
            >
                <Box
                    style={{
                        width: "100%",
                        height: "350px",
                        borderRadius: 10,
                        overflow: "hidden",
                    }}
                    pointerEvents="none"
                >
                    <div
                        style={{
                            width: "100%",
                            height: 350,
                            position: "relative",
                        }}
                    >

                        <Image
                            src={games[selectedGame]?.version?.thumbnail}
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover"
                            }}
                        />
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                background: "linear-gradient(0deg, #12121299 0%, rgba(0,0,0,0) 100%)",
                                top: 0,
                            }}
                        />

                        <Box
                            sx={{
                                position: "absolute",
                                bottom: 20,
                                left: 30,
                                color: "white",
                                display: "flex",
                                alignItems: "flex-end",
                                gap: 4,
                            }}
                        >
                            <Typography
                                variant="h4"
                                style={{

                                }}
                            >
                                {games[selectedGame]?.game?.name}
                            </Typography>

                            <Typography
                                variant="h6"
                                style={{

                                }}
                            >
                                Playtime: {Math.trunc(playTimes.find(playTime => playTime.game.appID === games[selectedGame]?.game?.appID)?.playTime / (1000 * 60)) || 0} minutes
                            </Typography>
                        </Box>
                    </div>

                </Box>

                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            height: 125,
                            zIndex: 1,
                            //backgroundColor: "#121212",
                            borderBottomLeftRadius: 8,
                            borderBottomRightRadius: 8,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {!isGameInstalled ?
                            <Button disabled={games[selectedGame].game.underReview && false} style={{ padding: 10, paddingRight: 20, paddingLeft: 20, fontSize: 20, fontWeight: "bold", color: "#fff" }} variant="contained" color="success" onClick={async () => {
                                callLoadingLine(0, games[selectedGame].game.name + " downloading...")
                                let curIndex = selectedGame
                                await window.ipcRenderer.invoke("installGame", { gameID: parseInt(games[selectedGame].game.appID), versionID: parseInt(games[selectedGame].versions[games[selectedGame].versions.length - 1].versionID), gameMeta: games[selectedGame] })

                                await window.ipcRenderer.invoke("getLocalGameList", { force: true }) // wait for anly locks to be released

                                setIsGameInstalled(true)

                                refreshGameList()
                            }}><Download style={{ marginRight: 10 }} />Download</Button>
                            :
                            isGameUpdate ?
                                <div style={{ display: "flex" }}>
                                    <Button disabled={games[selectedGame].game.underReview} style={{ padding: 10, fontSize: 20, fontWeight: "bold", color: "#fff", borderTopRightRadius: 0, borderBottomRightRadius: 0, paddingRight: 17 }} variant="contained" color="success" onClick={async () => {
                                        callLoadingLine(0, games[selectedGame].game.name + " downloading...")

                                        await window.ipcRenderer.invoke("installGame", { gameID: parseInt(games[selectedGame].game.appID), versionID: parseInt(games[selectedGame].versions[games[selectedGame].versions.length - 1].versionID), gameMeta: games[selectedGame] })

                                        await window.ipcRenderer.invoke("getLocalGameList", { force: true }) // wait for anly locks to be released

                                        refreshGameList()
                                        setTimeout(() => {
                                            setIsGameUpdate(false)
                                        }, 100);

                                    }}><Download style={{ marginRight: 10 }} />Update</Button>
                                    {!requiredUpdate && <Button style={{ padding: 10, fontSize: 20, fontWeight: "bold", color: "#fff", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} variant="contained" color="success" onClick={() => {
                                        callLoadingLine(0, games[selectedGame].game.name + " launching...")
                                        setTimeout(async () => {
                                            let launchResult = await window.ipcRenderer.invoke("launchGame", { appID: parseInt(games[selectedGame].game.appID), versionID: parseInt(games[selectedGame].version.versionID) })
                                            if (!launchResult) {
                                                snackCaller("Failed to launch game")
                                            }
                                        }
                                            , 500);
                                    }}><PlayArrow style={{ marginRight: 10 }} />Play</Button>}
                                </div>
                                :
                                <Button style={{ padding: 10, fontSize: 20, fontWeight: "bold", color: "#fff" }} variant="contained" color="success" onClick={() => {
                                    loadingCaller(true, games[selectedGame].game.name + " launching...")

                                    setTimeout(async () => {
                                        let launchResult = await window.ipcRenderer.invoke("launchGame", { appID: parseInt(games[selectedGame].game.appID), versionID: parseInt(games[selectedGame].version.versionID) })

                                        loadingCaller(false)
                                        if (!launchResult) {
                                            snackCaller("Failed to launch game")
                                        }
                                    }, 500);
                                }}><PlayArrow style={{ marginRight: 10 }} />Play</Button>}

                        <div
                            style={{
                                width: "100%",
                                height: 55,
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                            }}
                        >
                            <Dialog
                                open={infoModalVisible}
                                onClose={() => setInfoModalVisible(false)}
                            >
                                <DialogTitle>{games[selectedGame]?.game?.name}</DialogTitle>

                                <DialogContent>
                                    <DialogContentText>
                                        Installed Version: {games[selectedGame]?.version?.version} (Version ID: {games[selectedGame]?.version?.versionID})
                                        <br />
                                        Version Release: {new Date(games[selectedGame]?.version?.created).toLocaleString()}
                                        <br />
                                        Installed On: {new Date(games[selectedGame]?.installedOn).toLocaleString()}
                                        <br />
                                        Install Size: {Math.trunc(games[selectedGame]?.folderSize / 1024 / 1024)} MB
                                        {games[selectedGame]?.game?.underReview && <span style={{ color: "#ee0000", fontWeight: "bold" }}><br />Under Review</span>}
                                        {isGameInstalled && isGameUpdate && <span style={{ color: "#ee0000", fontWeight: "bold" }}><br />New update available!</span>}
                                    </DialogContentText>
                                </DialogContent>

                                <DialogActions>
                                    <Button
                                        onClick={() => {
                                            setInfoModalVisible(false)
                                        }}
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Button
                                variant='outlined'
                                sx={{
                                    mr: 2,
                                }}
                                onClick={() => {
                                    console.log(games[selectedGame])
                                    navigate("/games/" + games[selectedGame]?.game?.appID)
                                }}
                            >
                                Game Page
                            </Button>


                            <IconButton
                                onClick={() => {
                                    setInfoModalVisible(true)
                                }}
                                sx={{
                                    display: isGameInstalled ? "box" : "none",
                                }}
                            >
                                <Info />
                            </IconButton>
                            <Dialog
                                open={settingsModalVisible}
                                onClose={() => setSettingsModalVisible(false)}
                            >
                                <DialogTitle>Manage {games[selectedGame]?.game?.name}</DialogTitle>

                                <DialogContent>
                                    <InputLabel id="version-label">Select Version to install. Currently Installed: {isGameInstalled ? games[selectedGame]?.version?.version : "NONE"} (v{isGameInstalled ? games[selectedGame]?.version?.versionID : 0})</InputLabel>
                                    <Select
                                        labelId="version-label"
                                        id="version-select"
                                        value={selectedVersion}
                                        onChange={handleVersionChange}
                                        sx={{ mb: 2 }}
                                    >
                                        {[...games[selectedGame]?.versions]?.reverse()?.map((version) => {
                                            return (
                                                <MenuItem disabled={!version.isHardUpdate} value={version.versionID} sx={{
                                                    fontWeight: version.isHardUpdate ? "bolder" : "normal"
                                                }}>{version.version} (v{version.versionID}){version.isHardUpdate ? "*" : ""} - {new Date(version.created).toLocaleDateString()}</MenuItem>
                                            )
                                        })}
                                    </Select>

                                    <DialogContentText>
                                        You can install any version marked with an asterisk (*) to change to a different version of the game. Using this feature may cause issues with the game. Use with caution.
                                    </DialogContentText>

                                    <DialogActions>
                                        <Button
                                            onClick={() => {
                                                setSettingsModalVisible(false)
                                            }}
                                        >
                                            Cancel
                                        </Button>

                                        <Button
                                            disabled={selectedVersion === games[selectedGame]?.version?.versionID && games[selectedGame]?.versions.find(version => version?.versionID === selectedVersion)?.isHardUpdate && isGameInstalled === true}
                                            onClick={async () => {
                                                setSettingsModalVisible(false)
                                                callLoadingLine(0, games[selectedGame].game.name + " downloading...")
                                                let modifiedData = { ...games[selectedGame], version: games[selectedGame].versions.find(version => version.versionID === selectedVersion) }

                                                // check if the target version is the latest hard update, so we can enable auto updates again
                                                if (games[selectedGame]?.versions.find(version => version?.versionID === selectedVersion)?.isHardUpdate) {
                                                    await window.ipcRenderer.invoke("installGame", { gameID: games[selectedGame].game.appID, versionID: selectedVersion, gameMeta: modifiedData })
                                                    refreshGameList()
                                                    return
                                                }

                                                await window.ipcRenderer.invoke("installGame", { gameID: games[selectedGame].game.appID, versionID: selectedVersion, gameMeta: modifiedData, hold: true })

                                                refreshGameList()
                                            }}
                                        >
                                            Install
                                        </Button>
                                    </DialogActions>
                                </DialogContent>
                            </Dialog>

                            <IconButton
                                onClick={() => {
                                    setSelectedVersion(games[selectedGame]?.version?.versionID)
                                    setSettingsModalVisible(true)
                                }}
                            >
                                <Settings />
                            </IconButton>

                            <Dialog
                                open={deleteModalVisible}
                                onClose={() => setDeleteModalVisible(false)}
                            >
                                <DialogTitle>Delete {games[selectedGame]?.game?.name}</DialogTitle>

                                <DialogContent>
                                    <DialogContentText>
                                        Are you sure you want to delete this game?
                                        <br />
                                        This action cannot be undone.
                                    </DialogContentText>
                                </DialogContent>

                                <DialogActions>
                                    <Button
                                        onClick={() => {
                                            setDeleteModalVisible(false)
                                        }}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        onClick={async () => {
                                            await window.ipcRenderer.invoke("uninstallGame", { appID: parseInt(games[selectedGame]?.game?.appID) })
                                            refreshGameList()
                                            setDeleteModalVisible(false)
                                            setIsGameInstalled(false)
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <IconButton
                                onClick={() => {
                                    setDeleteModalVisible(true)
                                }}
                                sx={{
                                    display: isGameInstalled ? "box" : "none"
                                }}
                            >
                                <Delete />
                            </IconButton>
                        </div>
                    </Box>
                    <Box
                        style={{
                            padding: 10,
                            paddingTop: 3,
                            height: "calc(100% - 125px)",
                            overflowY: "auto",
                            textAlign: "left"
                        }}
                    >
                        <Markdown
                            markdown={"## What's new:" +
                                "\n\n\n" +
                                games[selectedGame]?.version?.changelog +
                                "\n\n\n" +
                                "## Description:" +
                                "\n\n\n" +
                                games[selectedGame]?.version?.description
                            }
                            style={{
                                width: "50%",
                                wordBreak: "break-all",
                                height: 300
                            }}
                        />
                    </Box>
                </Box>


            </Box>
        </div>
    )
};

export default Home;