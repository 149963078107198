import { useState, useEffect, Fragment } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Autocomplete, Badge, Drawer, TextField, createFilterOptions, Popover, createTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { EditNote, Login, Notifications, Person, Shield } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Util, { functionRoot, isElectron } from '../util';
import { isServerDown, setTheme, snackCaller } from '../App';

import logo from "../logo192.png"
import util from '../util';
import Image from './Image';


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

let pages

if (Util.isElectron()) {
    pages = ['Explore', 'Library', //'Tags'
    ];
} else {
    pages = ['Explore', 'Download', //'Tags'
    ];
}
const settings = pages

function ResponsiveAppBar(props) {
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user') || "false") || {
        username: '',
        avatar: '',
    });
    const [notifications, setNotifications] = useState([]);

    // State for notification panel
    const [anchorElNotifications, setAnchorElNotifications] = useState(null);

    const [searchAutoComplete, setSearchAutoComplete] = useState([]);
    const [sear, setSear] = useState("")

    useEffect(() => {
        if (user.username === "Anonymous" || "") {
            return setNotifications([
                {
                    message: "Login to see notifications!",
                    read: false
                }
            ])
        }
        util.postRequest("/api/user/getNotifications", {}, null, 'application/json', true).then((res) => {
            setNotifications([...res.notifications, ...res.systemNotifications].sort((a, b) => new Date(b.created) - new Date(a.created)))
        }).catch((err) => {
            setNotifications([
                {
                    message: "Error fetching notifications!",
                    read: false
                }
            ])
        })

        let interval = setInterval(() => {
            util.postRequest("/api/user/getNotifications", {}, null, 'application/json', true).then((res) => {
                setNotifications([...res.notifications, ...res.systemNotifications].sort((a, b) => new Date(b.created) - new Date(a.created)))
            }).catch((err) => {
                setNotifications([
                    {
                        message: "Error fetching notifications!",
                        read: false
                    }
                ])
            })
        }, 300000)

        setTheme(createTheme({
            palette: {
                mode: "dark",
                primary: {
                    main: user.colorTheme ? user.colorTheme : '#90caf9',
                },
                secondary: {
                    main: '#a6e3f9',
                },
                background: {
                    default: '#121212',
                    paper: '#212121',
                },
            },
        }))

        return () => {
            clearInterval(interval)
        };
    }, [user])

    useEffect(() => {
        if (!isServerDown) {
            Util.postRequest("/api/auth")
                .then((data) => {
                    if (data === false) {
                        setUser({
                            username: 'Anonymous',
                            avatar: '',
                            loggedIn: false,
                        });
                        localStorage.clear()
                        localStorage.setItem("ageCheck", "true")
                        window.dispatchEvent(new Event('storage'))
                    } else if (data.user) {
                        setUser(data.user);
                        util.postRequest("/api/user/getNotifications", {}, null, 'application/json', true).then((res) => {
                            setNotifications([...res.notifications, ...res.systemNotifications].sort((a, b) => new Date(b.created) - new Date(a.created)))
                        }).catch((err) => {
                            setNotifications([
                                {
                                    message: "Login to see notifications!",
                                    read: true
                                }
                            ])
                        })
                    } else {
                        setUser({
                            username: 'Anonymous',
                            avatar: '',
                        });
                    }
                })
                .catch((err) => {
                    setUser({
                        username: 'Anonymous',
                        avatar: '',
                    });
                })
        } else {
            setUser({
                username: 'Anonymous',
                avatar: '',
            });

            snackCaller("The server is currently down. Please try again later.")

            navigate("/")
        }
    }, [isServerDown])

    const [reactCrasher, setReactCrasher] = useState(false)

    useEffect(() => {
        Util.postRequest("/api/auth")
            .then((data) => {
                if (data === false) {
                    setUser({
                        username: 'Anonymous',
                        avatar: '',
                        loggedIn: false,
                    });
                    localStorage.clear()
                    localStorage.setItem("ageCheck", "true")
                    window.dispatchEvent(new Event('storage'))
                } else if (data.user) {
                    setUser(data.user);

                    setTheme(createTheme({
                        palette: {
                            mode: "dark",
                            primary: {
                                main: data.user.colorTheme ? data.user.colorTheme : '#90caf9',
                            },
                            secondary: {
                                main: '#a6e3f9',
                            },
                            background: {
                                default: '#121212',
                                paper: '#212121',
                            },
                        },
                    }))
                } else {
                    setUser({
                        username: 'Anonymous',
                        avatar: '',
                    });
                }
            })
            .catch((err) => {
                setUser({
                    username: 'Anonymous',
                    avatar: '',
                });
            })

        let strglist = (event) => {
            console.log("!!!")
            if (localStorage.getItem('user')) {
                setUser(JSON.parse(localStorage.getItem('user')));
            } else {
                setUser({
                    username: 'Anonymous',
                    avatar: ''
                });
            }
        }

        window.addEventListener("storage", strglist)

        let crasher = (event) => {
            // ask if the user wants to purposefully crash the app (CTRL + END)

            if (event.ctrlKey && event.key === "End") {
                if (window.confirm("Are you sure you want to crash the app?")) {
                    setReactCrasher(true)
                }
            }

            // ask if the user wants to purposefully crash the main process (ALT + END)

            if (event.altKey && event.key === "End" && isElectron()) {
                if (window.confirm("Are you sure you want to crash the main process?")) {
                    window.ipcRenderer.invoke("crash")
                }
            }

            if (event.ctrlKey && event.key === "i") {
                // open dev tools
                window.ipcRenderer.invoke("devTools")
            }
        }

        window.addEventListener("keyup", crasher)

        return () => {
            window.removeEventListener("storage", strglist)
            window.removeEventListener("keyup", crasher)
        };
    }, []);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    // Handle opening the notification panel
    const handleOpenNotifications = (event) => {
        util.postRequest("/api/user/getNotifications", {
            markAsRead: true
        }, null, 'application/json', true).then((res) => {
            setNotifications([...res.notifications, ...res.systemNotifications].sort((a, b) => new Date(b.created) - new Date(a.created)))
        }).catch((err) => {
            setNotifications([
                {
                    message: "Login to see notifications!",
                    read: true
                }
            ])
        })

        setAnchorElNotifications(event.currentTarget);
    };

    // Handle closing the notification panel
    const handleCloseNotifications = () => {

        setAnchorElNotifications(null);
    };

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    const navigate = useNavigate();

    // eslint-disable-next-line no-undef
    if (reactCrasher) return reactCrasher && <div>{CongratsYouCrashedTheApp}</div>;

    return (
        <div style={{
            //paddingBottom: "1.5rem"
            paddingTop: isElectron() && window.customTitlebar ? 24 : 0
        }}>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Image
                            src={logo}
                            alt="logo"
                            width="40"
                            height="40"
                            style={{ marginRight: '1rem', borderRadius: '50%' }}
                            className="logo-image"
                        />
                        <Typography
                            variant="h6"
                            noWrap
                            onClick={() => navigate('/')}
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                color: '#fff',
                                textDecoration: 'none',
                                cursor: 'pointer',
                            }}
                        >
                            Vorecade
                        </Typography>

                        {false && <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={() => setDrawerVisible(true)}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                        </Box>}
                        <Image
                            src={logo}
                            alt="icon"
                            width="40"
                            height="40"
                            style={{ marginRight: '1rem', borderRadius: '50%' }}
                            className="icon-image"
                        />
                        <Typography
                            variant="h5"
                            noWrap
                            onClick={() => navigate('/')}
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'none', sm: 'flex' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                color: 'inherit',
                                textDecoration: 'none',
                                cursor: 'pointer',
                            }}
                        >
                            Vorecade
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => {
                                if (page === "Download") {
                                    return (
                                        <Button
                                            key={page}
                                            onClick={() => {
                                                window.open("https://cdn.vorecade.com/defaults/downloads/clientVersionDownloads/Vorecade%20v1.1.3P.zip", "_blank");
                                            }}
                                            sx={{ my: 2, color: 'white', display: 'block' }}
                                        >
                                            {page}
                                        </Button>
                                    )
                                }

                                return (
                                    <Button
                                        key={page}
                                        onClick={() => navigate('/' + page.toLowerCase())}
                                        sx={{ my: 2, color: 'white', display: 'block' }}
                                    >
                                        {page}
                                    </Button>
                                )
                            })}
                        </Box>

                        <Autocomplete
                            sx={{
                                flexGrow: 1,
                                position: 'relative',
                                //borderRadius: "30%",
                                overflow: 'hidden',
                                marginLeft: 0,
                                alignSelf: 'center',
                            }}
                            freeSolo
                            disableClearable
                            groupBy={(option) => option.type}
                            filterOptions={createFilterOptions({
                                matchFrom: 'start',
                                stringify: (option) => option.title,
                            })}
                            onSelect={(e, v) => {
                                if (!v) return
                                switch (v.type) {
                                    case "Games":
                                        navigate("/games/" + v.UUID)
                                        break
                                    case "Users":
                                        navigate("/profile/" + v.UUID)
                                        break
                                    case "Tags":
                                        navigate("/tags/" + encodeURIComponent(v.title))
                                        break
                                }
                            }}
                            onChange={(e, v) => {
                                if (!v) return
                                switch (v.type) {
                                    case "Games":
                                        navigate("/games/" + v.UUID)
                                        break
                                    case "Users":
                                        navigate("/profile/" + v.UUID)
                                        break
                                    case "Tags":
                                        navigate("/tags/" + encodeURIComponent(v.title))
                                        break
                                }
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <MenuItem {...props} key={option.UUID} onClick={() => {
                                        switch (option.type) {
                                            case "Games":
                                                navigate("/")
                                                setTimeout(() => {
                                                    navigate("/games/" + option.UUID)
                                                }, 100);

                                                break
                                            case "Users":
                                                navigate("/profile/" + option.UUID)
                                                break
                                            case "Tags":
                                                navigate("/tags/" + encodeURIComponent(option.title))
                                                break
                                        }
                                    }}>
                                        {option.title}
                                    </MenuItem>
                                )
                            }}
                            getOptionLabel={(option) => option.title || ""}
                            options={searchAutoComplete}
                            renderInput={(params) => (
                                <TextField
                                    sx={{

                                    }}
                                    {...params}
                                    placeholder="Search Games / Tags / Users…"
                                    variant="standard"
                                    onChange={(value) => {
                                        if (value.target.value.length > 1) {
                                            util.postRequest("/api/searchAllSB_H", { query: value.target.value }).then((res) => {
                                                let t = []

                                                res.games.forEach((game) => {
                                                    t.push({ title: game.name, type: "Games", UUID: game.UUID })
                                                })

                                                res.users.forEach((user) => {
                                                    t.push({ title: user.username, type: "Users", UUID: user.UUID })
                                                })

                                                res.tags.forEach((tag) => {
                                                    t.push({ title: tag.name, type: "Tags", UUID: tag.UUID })
                                                })

                                                setSearchAutoComplete(t)
                                            })
                                                .catch((err) => {
                                                    snackCaller("The search failed. Please try again later.")
                                                })
                                        } else {
                                            setSearchAutoComplete([])
                                        }
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <SearchIconWrapper>
                                                <SearchIcon />
                                            </SearchIconWrapper>
                                        ),
                                        inputProps: {
                                            ...params.inputProps, // Spread the inputProps object
                                            style: { paddingLeft: '45px' }, // Add padding to the input
                                        },
                                    }}
                                />
                            )}
                        />

                        <Box sx={{ flexGrow: 0, marginLeft: "3rem" }}>

                            <IconButton
                                sx={{ marginRight: "1rem" }}
                                onClick={handleOpenNotifications} // Open notification panel
                            >
                                <Badge
                                    badgeContent={notifications.filter(n => !n.read).length} // Use actual notification count
                                    color="secondary"
                                >
                                    <Notifications />
                                </Badge>
                            </IconButton>

                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, position: "relative" }}>
                                    <Avatar sx={{ backgroundColor: user.avatar ? "#00000000" : stringToColor(user.username) }} alt={() => user.username}>{user.username.slice(0, 1)}</Avatar>
                                    {user.avatar && <Image src={user.avatar} alt="avatar" width="40" height="40" style={{ borderRadius: '50%', position: "absolute", objectFit: "cover", zIndex: 1 }} />}
                                </IconButton>
                            </Tooltip>

                            <Fragment>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    keepMounted
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&::before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <MenuItem sx={{ position: "relative" }} onClick={user.username !== "Anonymous" ? () => { navigate("/profile/" + user.UUID); handleCloseUserMenu() } : () => { navigate('/login'); handleCloseUserMenu() }}>
                                        <Avatar sx={{ backgroundColor: user.avatar ? "#00000000" : stringToColor(user.username) }} alt={() => user.username}>{user.username.slice(0, 1)}</Avatar> {user.username}
                                        {user.avatar && <Image src={user.avatar} alt="avatar" width="35" height="35" style={{ borderRadius: '50%', objectFit: "cover", position: "absolute", zIndex: 1, left: 8, marginRight: 8 }} />}
                                    </MenuItem>

                                    <Divider />

                                    {user.username !== "Anonymous" ? // if user is logged in
                                        <div>
                                            <MenuItem onClick={() => { navigate("/profile/" + user.UUID); handleCloseUserMenu() }}>
                                                <ListItemIcon>
                                                    <Person fontSize="small" />
                                                </ListItemIcon>
                                                Profile
                                            </MenuItem>

                                            <MenuItem onClick={() => { navigate("/contentManager"); handleCloseUserMenu() }}>
                                                <ListItemIcon>
                                                    <EditNote fontSize="small" />
                                                </ListItemIcon>
                                                Content Manager
                                            </MenuItem>

                                            <MenuItem onClick={() => {
                                                navigate("/userSettings");
                                                handleCloseUserMenu()
                                            }}>
                                                <ListItemIcon>
                                                    <Settings fontSize="small" />
                                                </ListItemIcon>
                                                Settings
                                            </MenuItem>

                                            {user.rank >= 4 ? // if user is admin
                                                <MenuItem onClick={() => {
                                                    navigate("/admin");
                                                    handleCloseUserMenu()
                                                }}>
                                                    <ListItemIcon>
                                                        <Shield fontSize="small" />
                                                    </ListItemIcon>
                                                    Admin
                                                </MenuItem>
                                                : <></>}

                                            <MenuItem onClick={() => {
                                                Util.getRequest("/api/auth/logout")
                                                localStorage.clear();
                                                localStorage.setItem("ageCheck", "true")
                                                window.dispatchEvent(new Event('storage'))
                                            }}>
                                                <ListItemIcon>
                                                    <Logout fontSize="small" />
                                                </ListItemIcon>
                                                Logout
                                            </MenuItem>
                                        </div>

                                        : // if user is not logged in

                                        <div>
                                            <MenuItem onClick={() => navigate('/login')}>
                                                <ListItemIcon>
                                                    <Login fontSize="small" />
                                                </ListItemIcon>
                                                Login
                                            </MenuItem>

                                            <MenuItem onClick={() => navigate('/register')}>
                                                <ListItemIcon>
                                                    <PersonAdd fontSize="small" />
                                                </ListItemIcon>
                                                Register
                                            </MenuItem>
                                        </div>
                                    }
                                    {isElectron() &&
                                        <Typography
                                            sx={{
                                                color: 'text.secondary',
                                                fontSize: 11,
                                                textAlign: 'center',
                                                mt: 1,
                                                mb: 1,
                                            }}
                                        >
                                            Running version: v{window.appVersion}
                                        </Typography>}
                                </Menu>
                            </Fragment>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>

            <Drawer
                anchor="left"
                open={drawerVisible}
                onClose={() => setDrawerVisible(false)}
                sx={{ display: { xs: 'block', md: 'none' } }}
            >
                <div
                    style={{
                        width: 250,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {settings.map((setting) => (
                        <MenuItem key={setting} onClick={() => setDrawerVisible(false)}>
                            <Typography textAlign="center">{setting}</Typography>
                        </MenuItem>
                    ))}
                </div>

            </Drawer>

            <Popover
                open={Boolean(anchorElNotifications)}
                anchorEl={anchorElNotifications}
                onClose={handleCloseNotifications}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box sx={{ width: 360, p: 2, height: 380 }}>
                    <Box
                        sx={{

                        }}
                    >
                        <Typography variant="h6" sx={{ mr: 2 }}>
                            Notifications
                        </Typography>

                    </Box>
                    <Divider sx={{ mt: 1.5 }} />
                    <Box
                        sx={{
                            height: 319,
                            width: "calc(100% + 48px)",
                            ml: -2,
                            overflowY: 'auto',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {notifications.length > 0 ? notifications.map((notification) => (
                            <Box
                                key={notification.id}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    borderBottom: 1,
                                    borderBottomColor: 'divider',
                                    borderBottomStyle: 'solid',
                                    py: 2,
                                    width: "calc(100% - 24px)",
                                    ml: -2
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Image
                                        src={notification.image ? notification.image : logo}
                                        alt="icon"
                                        width="40"
                                        height="40"
                                        style={{ marginRight: '1rem', borderRadius: '50%', marginLeft: "1.3rem" }}

                                    />

                                    <Typography variant="body2">
                                        {notification.message}
                                        {notification.created && <Typography
                                            variant="body2"
                                            sx={{
                                                color: 'text.secondary',
                                                fontSize: 11,
                                            }}
                                        >
                                            {new Date(notification.created).toDateString()}
                                        </Typography>}
                                    </Typography>
                                </Box>


                                {notification.link && <Button
                                    color="primary"
                                    sx={{
                                        textTransform: 'none',
                                    }}
                                    onClick={() => {
                                        handleCloseNotifications();
                                        navigate(notification.link);
                                    }}
                                >
                                    View
                                </Button>}
                            </Box>
                        ))
                            :
                            <Typography
                                variant="body2"
                                sx={{
                                    textAlign: 'center',
                                    mt: 2,
                                }}
                            >
                                No Notifications
                            </Typography>
                        }
                    </Box>
                </Box>
            </Popover>
        </div>
    );
}
export default ResponsiveAppBar;