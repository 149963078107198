import React, { Component, useEffect, useRef } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, MemoryRouter, HashRouter } from "react-router-dom";
import Index from './pages/index';
import Login from './pages/login';
import Register from './pages/register';
import Profile from './pages/profile';
import ManageGames from './pages/manageGames';
import Downlaod from './pages/download';
import Game from './pages/game';
import ResetPassword from "./pages/resetPassword";
import UserSettings from "./pages/userSettings";
import ContentManager from "./pages/contentManager";
import NoPage from './pages/noPage';
import AppBar from './components/AppBar';
import CreateGame from './pages/GameEditor/createGame';
import EditGame from "./pages/GameEditor/editGame";
import AgeCheck from "./pages/ageCheck";
import GameAnalyticsRoot from "./pages/GameAnalytics/analyticsRoot";
import AdminIndex from "./pages/Admin/index";
import Tags from './pages/Tags';
import Tag from "./pages/Tag"
import Library from "./pages/library";
import { ThemeProvider } from '@emotion/react';
import { CircularProgress, Container, Snackbar, Typography, createTheme, Button, Box, LinearProgress, Fab, IconButton, Tooltip } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { useNavigate } from 'react-router-dom';
import Privacy from './pages/privacy';
import Terms from './pages/terms';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import WebFont from "webfontloader"
import util, { isElectron } from './util';

// Supports weights 100-900
import '@fontsource-variable/montserrat/wght.css';
import { Close, Remove, StopOutlined } from '@mui/icons-material';

const fontOptions = {
  google: {
    families: ['Russo One']
  }
};

WebFont.load(fontOptions);

export let theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#f69565',
    },
    secondary: {
      main: '#a6e3f9',
    },
    background: {
      default: '#121212',
      paper: '#212121',
    },
  },
});

export let setTheme = (newTheme) => {
  theme = newTheme
}

export let snackCaller = () => { }
export let loadingCaller = () => { }
export let navigate = () => { }
export let callReportModal = () => { }
export let callGenericModal = () => { }
export let hideModal = () => { }
export let callLoadingLine = () => { }
export let isOffline = false
export let isServerDown = false
export let callFAB = () => { }

if ("mediaSession" in navigator) {
  navigator.mediaSession.setActionHandler('play', function () { /* Code excerpted. */ });
  navigator.mediaSession.setActionHandler('pause', function () { /* Code excerpted. */ });
  navigator.mediaSession.setActionHandler('seekbackward', function () { /* Code excerpted. */ });
  navigator.mediaSession.setActionHandler('seekforward', function () { /* Code excerpted. */ });
  navigator.mediaSession.setActionHandler('previoustrack', function () { /* Code excerpted. */ });
  navigator.mediaSession.setActionHandler('nexttrack', function () { /* Code excerpted. */ });
  navigator.mediaSession.setActionHandler('stop', function () { /* Code excerpted. */ });
  navigator.mediaSession.setActionHandler('seekto', function () { /* Code excerpted. */ });
}

function MNav() {
  const nav = useNavigate();
  navigate = (path) => {
    nav(path)
  }
  return (
    <></>
  )
}

const ReportModal = ({ open, close, data }) => {
  const [reason, setReason] = React.useState("")
  const [selectedPrereason, setSelectedPrereason] = React.useState(-1)

  let preReasons

  switch (data.type) {
    case "game":
      preReasons = [
        "Game Doesn't Run",
        "Game Doesn't Download",
        "Malware or Virus",
        "Underage Content",
        "Hate Speech or Racism",
        "Spam",
        "Other"
      ]
      break;

    case "user":
      preReasons = [
        "Underage User",
        "Hate Speech or Racism",
        "Spam",
        "Hacked Account",
        "Other"
      ]
      break;

    case "GameComment":
      preReasons = [
        "Off-Topic",
        "Hate Speech or Racism",
        "Spam",
        "Other"
      ]
      break;

    case "forumpost":
      preReasons = [
        "Off-Topic",
        "Hate Speech or Racism",
        "Spam",
        "Other"
      ]
      break

    case "galleryitem":
      preReasons = [
        "Underage Content",
        "Stolen Content",
        "Hate Speech or Racism",
        "Spam",
        "Other",
      ]
      break

    default:
      preReasons = [
        "Something has gone terribly wrong and you shouldn't be seeing this.",
      ]
      break;
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Report {data.title}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please provide a reason for reporting this {data.type}.
          </DialogContentText>

          <FormControl fullWidth
            sx={{
              marginTop: 2
            }}
          >
            <InputLabel id="prereasonlab">Reason</InputLabel>
            <Select
              labelId="prereasonlab"
              value={selectedPrereason}
              onChange={(e) => setSelectedPrereason(e.target.value)}
              label="Reason"
              fullWidth
              variant="outlined"
            >
              <MenuItem disabled value={-1}>
                Select a reason
              </MenuItem>
              {preReasons.map((reason, index) => (
                <MenuItem key={index} value={index}>{reason}</MenuItem>
              ))}
            </Select>


            <TextField
              sx={{
                marginTop: 2
              }}
              label="Additional Details"
              multiline
              minRows={3}
              fullWidth
              inputProps={{ maxLength: 300, minLength: 16 }}
              helperText={`${reason.length}/300`}
              variant="outlined"
              value={reason}
              required={selectedPrereason === 6}
              onChange={(e) => setReason(e.target.value)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setReason("")
              setSelectedPrereason(-1)
              close()
            }}
          >Cancel</Button>
          <Button
            onClick={() => {
              if (selectedPrereason === -1) {
                return snackCaller("Please select a reason.")
              } else if (selectedPrereason === 6 && reason.length < 16) {
                return snackCaller("Please provide a reason with at least 16 characters.")
              }

              util.postRequest("/api/abuse/report", {
                contentID: data.id,
                reason,
                contentType: data.type,
                preReasonID: selectedPrereason
              })

              setReason("")
              setSelectedPrereason(-1)

              close()
            }}

          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const GenericModal = ({ open, close, title, content, options }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent
        >
          {typeof content === "string" ?
            <DialogContentText id="alert-dialog-description">
              {content}
            </DialogContentText>
            :
            content
          }
        </DialogContent>
        <DialogActions>
          {options.map((option, index) => (
            <Button
              color={option.color || "primary"}
              key={index}
              onClick={() => {
                if (option.onClick) option.onClick(close)
              }}
            >
              {option.text}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </div>
  );
}

class ErrorCatcher extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      wasReportFailed: false
    }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    console.log("Error in component", error, errorInfo)

    util.analyticsHandler("clientRendererUnhandledException", {
      error: error.toString(),
      stack: errorInfo.componentStack
    })

    this.setState({ error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      return <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <marquee>AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA</marquee>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            m: 5
          }}
        >
          <Tooltip title="Click to view source" arrow>
            <img
              src={require("./images/errmokou.webp")} style={{ width: 200, height: 200, marginBottom: 20, cursor: "pointer" }}
              onClick={() => {
                // open the image source in a new tab
                window.open("https://gelbooru.com/index.php?page=post&s=view&id=9361545", "_blank")
              }}
            />
          </Tooltip>
          <Typography
            variant="h3"
          >
            Something went wrong...
          </Typography>

          <Typography
            variant="h4"
          >
            {this.state.error?.toString() || "An error occurred."}
          </Typography>

          <Typography
            variant="body1"
          >
            {this.state.errorInfo?.componentStack || "No stack trace available."}
          </Typography>

          <Typography
            variant="body2"
          >
            {!this.state.wasReportFailed ? "This error has been reported to the developers." : "Failed to report this error to the developers. Please report this error manually."}
          </Typography>

          <Button
            variant="outlined"
            onClick={() => {
              navigate("/")
              window.location.reload()
            }}
          >
            Reload
          </Button>
        </Box>

        <marquee>
          AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
        </marquee>
      </Box>
    }

    return this.props.children
  }
}

function App() {
  const [snackVisible, setSnackVisible] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState();
  const [loaderVisible, setLoaderVisible] = React.useState(false);
  const [loaderMessage, setLoaderMessage] = React.useState("")
  const [reportModalVisible, setReportModalVisible] = React.useState(false)
  const [reportData, setReportData] = React.useState({})
  const [genericModalVisible, setGenericModalVisible] = React.useState(false)
  const [genericModalData, setGenericModalData] = React.useState({ title: "", content: "", options: [] })
  const [loadingLinePercentage, setLoadingLinePercentage] = React.useState(-1)
  const [loadingLineText, setLoadingLineText] = React.useState("")
  const [isAgeChecked, setIsAgeChecked] = React.useState(localStorage.getItem("ageCheck") === "true" || isElectron())
  const [fabVisible, setFabVisible] = React.useState(false)
  const [fabIcon, setFabIcon] = React.useState("")
  const [fabText, setFabText] = React.useState("")
  const [fabCallback, setFabCallback] = React.useState({ func: null })
  const [th, setTh] = React.useState(theme)
  const thref = useRef(th)
  const [incompatible, setIncompatible] = React.useState(false)
  const [incompatibleReason, setIncompatibleReason] = React.useState("")

  // try

  useEffect(() => {
    try { // Object.hasOwn({})
      Object.hasOwn({})
    } catch (e) {
      setIncompatible(true)
      setIncompatibleReason("Vorecade requires Object.hasOwn() which is not supported by your browser. Please update your browser to the latest version.")
    }
  }, [])

  useEffect(() => {
    thref.current = th
  }, [th])

  useEffect(() => {
    try {
      if (isElectron()) {
        window.ipcRendererAddListener("CALLFUNCTION", (event, arg) => {
          eval(arg)
        })
      }

      // create request cache

      caches.open("request-cache")

      util.getRequest("/204", null, true).then(() => {
        isOffline = false
        isServerDown = false
      }).catch(() => {
        isOffline = true
        isServerDown = true
      })


      if (!isElectron()) {
        util.postRequest("/api/scii", {
          event: "appStart",
          PCWUUID: localStorage.getItem('PCWUUID') || util.generatePCWUUID(),
          platform: "web"
        }).catch((e) => {
          console.log("Error in SCII!!!", e)
        })
      }
    } catch (e) {
      console.log("Error in BACKFILLDB", e)
    }

    const updateOnlineStatus = () => {
      isOffline = !navigator.onLine

      if (isOffline) {
        setLoadingLinePercentage(0)
        setLoadingLineText("Internet connection lost.")
        isServerDown = false
      } else {
        util.getRequest("/204", null, true).then(() => {
          isOffline = false
          isServerDown = false
          setLoadingLinePercentage(-1)
          setLoadingLineText("")
        }).catch(() => {
          isOffline = true
          isServerDown = true
          setLoadingLinePercentage(0)
          setLoadingLineText("Server connection lost.")
        })
      }
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    (async () => {
      while (navigator.onLine) {
        await new Promise(resolve => setTimeout(resolve, 20000))
        try {
          if (loadingLineText === "Internet connection lost.") return
          let res = await util.getRequest("/204").catch(() => {
            return { code: 0 }
          })
          if (!res?.code) {
            console.log("SERVER CONNECTION LOST", res)
            isOffline = true
            isServerDown = true
            setLoadingLinePercentage(0)
            setLoadingLineText("Server connection lost.")
          } else if (isOffline) {
            console.log("SERVER CONNECTION RESTORED", res)
            isOffline = false
            isServerDown = false
            setLoadingLinePercentage(-1)
            setLoadingLineText("")
          } else {
            if (localStorage.getItem("token") == null && loadingLinePercentage < 1 && loadingLineText === "") {
              setLoadingLinePercentage(0)
              setLoadingLineText("Please log in to use online features.")
            }
          }
        } catch (e) {
          console.log("Error in online check", e)
          if (!isOffline) {
            setLoadingLinePercentage(0)
            setLoadingLineText("Unable to determine server status. (C_CONCHKERR)")
          }
          isOffline = true
          isServerDown = true
        }
      }
    })()

    let int = setInterval(() => {
      if (thref.current.palette.primary.main !== theme.palette.primary.main) {
        setTh(theme)
      }
    }, 200)

    return () => {
      clearInterval(int)
    }
  }, [])

  snackCaller = (message, children) => {
    setSnackMessage(message)
    setSnackVisible(true)
  }

  window.snackCaller = snackCaller

  loadingCaller = (v, msg) => {
    setLoaderVisible(v)
    setLoaderMessage(msg || "Please Wait")
  }

  callReportModal = (data) => {
    setReportModalVisible(true)
    setReportData(data)
  }

  callGenericModal = (title, content, options) => {
    setGenericModalVisible(true)
    setGenericModalData({ title, content, options })
  }

  hideModal = () => {
    setGenericModalVisible(false)
    setReportModalVisible(false)
  }

  callLoadingLine = (percentage, text) => {
    setLoadingLinePercentage(percentage)
    text && setLoadingLineText(text)
  }

  callFAB = (visible, icon, text, callback) => {
    setFabVisible(visible)
    setFabIcon(icon)
    setFabText(text)
    setFabCallback({
      func: callback
    })
  }

  if (incompatible && !isElectron()) {
    return <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <marquee>AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA</marquee>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          m: 5
        }}
      >
        <Tooltip title="Click to view source" arrow>
          <img
            src={require("./images/errmokou.webp")} style={{ width: 200, height: 200, marginBottom: 20, cursor: "pointer" }}
            onClick={() => {
              // open the image source in a new tab
              window.open("https://gelbooru.com/index.php?page=post&s=view&id=9361545", "_blank")
            }}
          />
        </Tooltip>
        <Typography
          variant="h3"
        >
          Unsupported Browser
        </Typography>

        <Typography
          variant="h5"
          sx={{
            textAlign: "center"
          }}
        >
          Sorry! {incompatibleReason}
        </Typography>

        <Typography
          variant="body2"
        >
          or you can use the client instead!
        </Typography>

        <Button
          variant="outlined"
          onClick={() => {
            window.open("https://cdn.vorecade.com/defaults/downloads/clientVersionDownloads/Vorecade%20v1.1.3P.zip", "_blank");
          }}
        >
          Download Client
        </Button>

        <Button
          variant="text"
          onClick={() => {
            setIncompatible(false)
          }}
        >
          Continue with Unsupported Browser
        </Button>
        <Typography
          variant="body2"
          sx={{
            mt: -2
          }}
        >
          Might Not Work!
        </Typography>
      </Box>

      <marquee>
        AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
      </marquee>
    </Box>
  }

  return (
    <div className="App">
      <ThemeProvider
        theme={th}
      >
        {
          isElectron() && window.customTitlebar &&
          <Box
            sx={{
              width: "100%",
              height: 24,
              top: 0,
              left: 0,
              zIndex: 1000,
              backgroundColor: th.palette.primary.main + "45",
              backdropFilter: "blur(5px)",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              color: "#fff",
              fontFamily: "Russo One",
              fontSize: 14,
              overflow: "hidden",
              position: "fixed",
            }}
          >
            <div
              style={{
                "-webkit-app-region": "drag",
                width: "100%",
                height: "100%",
                display: "flex",
              }}
            />
            <IconButton
              size="small"
              sx={{
                zIndex: 1,
                height: 24,
                borderRadius: 0,
              }}
              onClick={() => {
                window.ipcRenderer.invoke("minimize", { id: window.windowID })
              }}
            >
              <Remove />
            </IconButton>

            <IconButton
              size="small"
              sx={{
                zIndex: 1,
                height: 24,
                borderRadius: 0,
              }}
              onClick={() => {
                window.ipcRenderer.invoke("toggleMaximize", { id: window.windowID })
              }}
            >
              <StopOutlined />
            </IconButton>

            <IconButton
              size="small"
              sx={{
                zIndex: 1,
                height: 24,
                borderRadius: 0,
                '&:hover': {
                  backgroundColor: "#d43939"
                }
              }}
              onClick={() => {
                window.ipcRenderer.invoke("close", { id: window.windowID })
              }}
            >
              <Close />
            </IconButton>
          </Box>}

        <ErrorCatcher>
          <GenericModal open={genericModalVisible} close={() => {
            setGenericModalVisible(false);
            setTimeout(() => {
              setGenericModalData({ title: "", content: "", options: [] })
            }, 100);
          }} title={genericModalData.title} content={genericModalData.content} options={genericModalData.options} />
          <ReportModal data={reportData} close={() => { setReportModalVisible(false) }} open={reportModalVisible} />
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              pointerEvents: loaderVisible ? "all" : "none",
              opacity: loaderVisible ? 1 : 0,
              zIndex: 1000,
              backdropFilter: "blur(5px)",
              transition: "0.5s",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                marginRight: 30,
              }}
            >
              {loaderMessage}
            </Typography>

            <CircularProgress
              color="primary"
            />
          </div>
          <CssBaseline />
          <HashRouter
          >
            <div
              style={{
                // top left to bottom right gradient
                background: "linear-gradient(45deg, #121212, #202020)",
                position: "fixed",
                width: "100%",
                height: "100%",
                zIndex: -1000,

              }}
            >

            </div>
            {isAgeChecked && <AppBar />}
            <Fab
              sx={{
                position: "fixed",
                bottom: 20,
                right: 20,
                display: fabVisible ? "flex" : "none",
                zIndex: 1000,
                justifyContent: "center",
                alignItems: "center",
                gap: 1
              }}
              variant={fabText ? "extended" : "round"}
              color="primary"
              onClick={() => {
                if (fabCallback.func) fabCallback.func()
                setFabVisible(false)
              }}
            >
              {fabIcon}
              {fabText}
            </Fab>
            <MNav />
            <Snackbar open={snackVisible} autoHideDuration={6000} onClose={() => setSnackVisible(false)} message={snackMessage} />

            <Snackbar open={() => (loadingLinePercentage >= 0)}>
              <Box
                sx={{
                  width: "100%",
                  position: "fixed",
                  bottom: 0,
                  left: 0,
                  zIndex: 100,
                  display: loadingLinePercentage >= 0 ? "block" : "none",
                }}
              >
                <Typography>{loadingLineText}</Typography>
                <LinearProgress variant="determinate" value={loadingLinePercentage} />
              </Box>
            </Snackbar>

            <Container
              maxWidth="xl"
            >
              {isAgeChecked && <Routes>
                <Route path="/" element={<Index />} />
                <Route path="/explore" element={<Index />} />
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Register />} />
                <Route path="profile/*" element={<Profile />} />
                <Route path="contentManager" element={<ContentManager />} />
                <Route path="createGame" element={<CreateGame />} />
                <Route path="editGame/*" element={<EditGame />} />
                <Route path="manageGames" element={<ManageGames />} />
                <Route path="download" element={<Downlaod />} />
                <Route path="library/*" element={<Library />} />
                <Route path="games/*" element={<Game />} />
                <Route path="tags/*" element={<Tag />} />
                <Route path="tags" element={<NoPage />} />
                <Route path="resetPassword" element={<ResetPassword />} />
                <Route path="userSettings" element={<UserSettings />} />
                <Route path="analytics/*" element={<GameAnalyticsRoot />} />
                <Route path="admin/*" element={<AdminIndex />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="terms" element={<Terms />} />
                <Route path="*" element={<Index />} />
              </Routes>}

              {!isAgeChecked && <AgeCheck setIsAgeChecked={setIsAgeChecked} />}
            </Container>
          </HashRouter>
        </ErrorCatcher>
      </ThemeProvider>
    </div >
  );
}

export default App;
